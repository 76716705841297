var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ controler: true }},[_c('div',{staticClass:"control"},[(_vm.type == 'checkbox')?_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.Datos),function(n,index){return _c('v-checkbox',{key:index,staticClass:"wrap-form-selec check text-addons",class:{ none_delete: n.deleted == true },attrs:{"hide-details":"","required":_vm.Required,"rules":_vm.Required ? [(c) => c.length != 0 || 'Obligatorio'] : [],"color":_vm.color,"disabled":n.active == false ||
          n.originalProduct.isAvailable == false ||
          n.deleted == true,"value":n},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"label-select-form text-caption",class:_vm.radioGroup == n ? 'font-weight-medium' : 'font-weight-light',style:(`color:${_vm.selected ? '#6c6c6c' : _vm.colorAlertSpin};`)},[_vm._v(" "+_vm._s(n.originalProduct.name)+" "),_c('strong',{staticClass:"text-caption label-Price",style:(`color:${_vm.color};`)},[_vm._v("+"+_vm._s(_vm._f("currency")(n.price)))])])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}),1):_vm._e(),(_vm.type == 'radio' && _vm.loading)?_c('div',{staticClass:"wrap-radio"},[_vm._l((_vm.sortedArray),function(n,index){return _c('div',{key:index,staticClass:"wrap-form-selec my-1 text-addons",on:{"click":function($event){(n.calValue = _vm.uncheck(n)), _vm.addos_id, _vm.type}}},[_c('div',{staticClass:"label-select-form text-caption label-addons",class:_vm.radio == n ? 'font-weight-medium' : 'font-weight-light',style:(`color:${_vm.radio ? '#6c6c6c' : _vm.colorAlertSpin};`)},[_vm._v(" "+_vm._s(n.originalProduct.name)+" "),_c('strong',{staticClass:"text-caption label-Price",style:(`color:${_vm.color};`)},[_vm._v("+"+_vm._s(_vm._f("currency")(n.price)))])]),(_vm.valueEdit)?_c('div',{staticClass:"radio-control",style:(`background-color:${
            _vm.radio.idDoc == n.idDoc ? _vm.color : '#f2f2f2'
          }`)},[(_vm.radio.idDoc == n.idDoc)?_c('v-icon',{attrs:{"x-small":"","dark":""}},[_vm._v("fa-solid fa-check")]):_vm._e()],1):_c('div',{staticClass:"radio-control",style:(`background-color:${_vm.radio == n ? _vm.color : '#f2f2f2'}`)},[(_vm.radio == n)?_c('v-icon',{attrs:{"x-small":"","dark":""}},[_vm._v("fa-solid fa-check")]):_vm._e()],1)])}),_c('v-checkbox',{staticClass:"check-oculto",attrs:{"required":_vm.Required,"disabled":"","rules":_vm.Required ? [(v) => !!v || 'Obligatorio'] : []},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}})],2):_vm._e(),(_vm.type == 'spin')?_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-caption text-left",staticStyle:{"display":"flex"},attrs:{"width":"100px"}},[(_vm.min != 0)?_c('p',{style:(`color:${_vm.colorAlert}`)},[_vm._v("(Min: "+_vm._s(_vm.min)+")")]):_vm._e(),_c('p',{style:(`color:${_vm.colorAlert}`)},[_vm._v("(Max: "+_vm._s(_vm.max)+")")]),_c('v-checkbox',{staticClass:"check-oculto text-addons",attrs:{"required":_vm.Required,"disabled":"","rules":_vm.Required ? [(v) => !!v || 'Obligatorio'] : []},model:{value:(_vm.spinValid),callback:function ($$v) {_vm.spinValid=$$v},expression:"spinValid"}})],1),_vm._l((_vm.spines),function(spin,index){return _c('div',{key:index,staticClass:"sumar_resta m-2 wrap-form-selec",class:{
          none_delete: spin.deleted == true,
          disabled_spin:
            spin.active == false || spin.originalProduct.isAvailable == false,
        }},[_c('div',{staticClass:"label-select-form text-caption text-addons label-addons",style:(`color:${_vm.spinValid ? '#6c6c6c' : _vm.colorAlertSpin};`)},[_vm._v(" "+_vm._s(spin.originalProduct.name)+" "),_c('strong',{staticClass:"text-caption label-Price",style:(`color:${_vm.color};`)},[_vm._v("+"+_vm._s(_vm._f("currency")(spin.price)))])]),_c('div',{staticClass:"wrap-spin-controler"},[_c('v-btn',{attrs:{"icon":"","small":"","outlined":"","depressed":""},on:{"click":function($event){_vm.pinResul > 0 && spin.resul > 0
                ? (spin.resul--, _vm.pinResul--)
                : spin.resul}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus ")])],1),_c('div',{staticClass:"label"},[_c('b',[_vm._v(_vm._s(_vm.pinResul == spin.resul ? _vm.pinResul : spin.resul))])]),_c('v-btn',{attrs:{"color":_vm.color,"small":"","outlined":"","icon":""},on:{"click":function($event){_vm.pinResul < _vm.max ? (spin.resul++, _vm.pinResul++) : spin.resul}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)],1)])})],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }