import Vue from "vue";
import Vuex, { Store } from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "ocho-menu",
  storage: window.localStorage,
  modules: ["RespalOrder", "dataInfo","allBusiness","getSelectBusiness","orderResponse"],
});

function makeid(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export default new Vuex.Store({
  state: {
    SnackbarAlert: {
      text: "",
      timeout: 2000,
      btn_closed: false,
      icon: false,
      type: "",
    },
    e1: 1,
    orderResponse:null,
    pathQr: "",
    descriptionHeader: "",
    RespalOrder: [],
    ordercant: 0,
    allBusiness:[],
    processOrder: 0,
    menu: [],
    selectBusiness:null,
    addonsData: [],
    chipsMenu: [],
    dataInfo: {},
    addons: [],
    data: {
      business: "",
      channelVersion: require("./../../package.json").version,
      instructions: "",
      // location: [14.0996153, -87.1906557],
      products: [],
      tin: "",
      tinName: "",
      type: "",
      userName: "",
      userPhone: "",
      table: "",
    },
  },
  mutations: {
    addOrdersProduct(state, orders) {
      // //// console.debug('addOrdersProduct', orders);
      state.data.products.push(orders);

      return state.data;
    },
    vipDataUser(state, obj) {
      return new Promise((resolve, reject) => {
        state.data.userPhone = obj.phone;
        state.data.userName = obj.name;
        return resolve(state.data);
      });
    },
    addons(state, obj) {
      return new Promise((resolve, reject) => {
        state.addons.push(obj);
        return resolve(state.addons);
      });
    },
    orderResponse(state, obj) {
      return new Promise((resolve, reject) => {
        state.orderResponse = obj;
        return resolve(state.orderResponse);
      });
    },
    limpiarProduct(state, eliminar) {
      return new Promise((resolve, reject) => {
        state.data.products = [];
        return resolve(state.data);
      });
    },
    AddComersId(state, id) {
      state.data.business = id;
      return state.business;
    },
    setE1(state, value) {
      state.e1 = value;

      return state.e1;
    },
    AlertSnackbar(state, config) {
      state.SnackbarAlert = config;
      return state.SnackbarAlert;
    },
    AddpathQr(state, path) {
      state.pathQr = path;
      return state.pathQr;
    },
    AddAddonsData(state, data) {
      state.addonsData = data;
      return state.addonsData;
    },
    descripHeader(state, value) {
      state.descriptionHeader = value;
      return state.descriptionHeader;
    },
    AddMenu(state, data) {
      state.menu = data;
      // //// console.debug('estado menu')
      return state.menu;
    },

    AddchipsMenu(state, data) {
      state.chipsMenu = data;
      return state.chipsMenu;
    },
    AddallBusiness(state, data) {
      state.allBusiness = data;
      return state.allBusiness;
    },
    AddInfoClient(state, obj) {
      state.data.userName = obj.name;
      state.data.userPhone = obj.phone;
      state.data.table = obj.table;
      state.data.email = obj.email;

      return state.addons;
    },
    orderEdit(state, datos) {
      // console.debug(datos[0]); //Datos que eliminara
      // console.debug(datos[1]); //Dato para actualizar
      var index = state.RespalOrder.indexOf(datos[0]);
      state.RespalOrder[index] = datos[1];

      return state.RespalOrder;
    },
    RespalOrder(state, obj) {
      var code =
        "ORD-" + makeid(4) + parseInt(Math.floor(Math.random() * 99) + 1);
      obj.IdRespal = code;
      state.RespalOrder.push(obj);
      return state.RespalOrder;
    },
    orderCant(state, d) {
      return new Promise((resolve, reject) => {
        state.ordercant = state.ordercant + d;
        return resolve(state.ordercant);
      });
    },
    loaderProcess(state, e) {
      return new Promise((resolve, reject) => {
        state.processOrder = state.processOrder + e;
        return resolve(state.processOrder);
      });
    },
    removeOrder(state, len) {
      var index = state.RespalOrder.indexOf(len);

      state.RespalOrder.splice(index, 1);

      return state.RespalOrder;
    },
    addDataInfo(state, e) {
      state.dataInfo = e;
      return state.dataInfo;
    },
    addSelectBusiness(state, e) {
      state.selectBusiness = e;
      return state.selectBusiness;
    },
    Clean_Data(state) {
      state.RespalOrder = [];
      state.data.products = [];
      // //// console.log(state.dataInfo);
      // return state.dataInfo;
    },
    limpiarProduct(state) {
      state.data.products = [];
    },
  },
  actions: {
    cambiData(state) {
      state.commit("cambiData");
    },

    _vipDataUser: ({ commit, state }, obj) => {
      commit("vipDataUser", obj);
    },
    addAddons: ({ commit, state }, datos) => {
      commit("addons", datos);
    },
    _descripHeader: ({ commit, state }, value) => {
      commit("descripHeader", value);
    },
    _setE1: ({ commit, state }, value) => {
      commit("setE1", value);
    },
    _setAllBusiness: ({ commit, state }, value) => {
      commit("AddallBusiness", value);
    },
    order_Edit: ({ commit, state }, datos) => {
      commit("orderEdit", datos);
    },
    Add_Menu: ({ commit, state }, datos) => {
      commit("AddMenu", datos);
    },
    limpiar_Product: ({ commit, state }) => {
      commit("limpiarProduct");
    },
    Add_chipsMenu: ({ commit, state }, datos) => {
      commit("AddchipsMenu", datos);
    },
    Add_AddonsData: ({ commit, state }, datos) => {
      commit("AddAddonsData", datos);
    },
    Alert_: ({ commit, state }, config) => {
      commit("AlertSnackbar", config);
    },
    orderResponse_: ({ commit, state }, obj) => {
      commit("orderResponse", obj);
    },

    Add_pathQr: ({ commit, state }, id) => {
      commit("AddpathQr", id);
    },
    limpiarProduct: ({ commit, state }, id) => {
      commit("limpiarProduct", id);
    },
    Add_ComersId: ({ commit, state }, id) => {
      commit("AddComersId", id);
    },
    CleanData: ({ commit, state }) => {
      commit("Clean_Data");
    },
    addData_info: ({ commit, state }, datos) => {
      commit("addDataInfo", datos);
    },
    Add_selectBusiness: ({ commit, state }, datos) => {
      commit("addSelectBusiness", datos);
    },
    addRespalOrders: ({ commit, state }, data) => {
      commit("RespalOrder", data);
    },
    AddInfoClient: ({ commit, state }, data) => {
      commit("AddInfoClient", data);
    },
    orderCanti: ({ commit, state }, e) => {
      commit("orderCant", e);
    },
    loader_Process: ({ commit, state }, e) => {
      commit("loaderProcess", e);
    },

    remove: ({ commit, state }, order) => {
      commit("removeOrder", order);
    },
    agregarOrder: ({ commit, state }, orders) => {
      commit("addOrdersProduct", orders);
    },
  },
  getters: {
    dataClientOrder: (state) => {
      return state.data;
    },
    valueE1: (state) => {
      return state.e1;
    },
    descriptionHeader: (state) => {
      return state.descriptionHeader;
    },
    allBusiness: (state) => {
      return state.allBusiness;
    },
    getSelectBusiness: (state) => {
      return state.selectBusiness;
    },
    getOrderResponse: (state) => {
      return state.orderResponse;
    },
    CarShop: (state) => {
      return state.RespalOrder;
    },
    get_pathQr: (state) => {
      return state.pathQr;
    },
    get_chips: (state) => {
      return state.chipsMenu;
    },
    infoData: (state) => {
      return state.dataInfo;
    },
    cantOrder: (state) => {
      return state.ordercant;
    },
    get_Menu_state: (state) => {
      return state.menu;
    },
    Alert_: (state) => {
      return state.SnackbarAlert;
    },
    process: (state) => {
      return state.processOrder;
    },
    get_addonsData: (state) => {
      return state.addonsData;
    },

    Orders: (state) => {
      return state.data.products;
    },
    addonss: (state) => {
      return state.addons;
    },
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
