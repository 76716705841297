<template>
  <div class="SectTemplate">
    <div class="wrap-scroll-x">
      <div
        class="card-category"
        style=""
        v-for="(i, index) in data"
        :key="index"
        :style="`background-image:url(${i.image})`"
      >
        <h3>{{ i.Name }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectProduct",

  props: {
    data: {
      typeof: Object,
    },
    titulo: {
      typeof: String,
      default: "Titulo",
    },
    label: {
      typeof: String,
    },
    color: {
      typeof: "",
      default: "#000",
    },
  },

  data: () => ({}),
};
</script>

<style lang="scss">
.SectTemplate {
  .wrap-scroll-x {
    height: 115px;
    // background-color: aqua;
    width: auto;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .card-category {
      height: 100px;
      width: 250px !important;
      background-color: aquamarine;
      border-radius: 1em;
      padding-left: 100px;
      padding-right: 100px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      background-size: 100%;
      background-position: center;
      background-color: rgba(17, 17, 17, 0);
      color: #fff;
      text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.685);
    }
  }
}
.sombra {
  box-shadow: inset 0px -2px 5px #27272771;
}
</style>
