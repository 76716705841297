<template>
  <nav>
    <v-container class="nav">
      <v-spacer> </v-spacer>
      <transition name="bounce">
        <router-link
          v-if="flag == true"
          class="flecha-back"
          :to="{ path: `/${this.nameIdC}`, params: { _id: this.nameIdC } }"
        >
          <v-btn class="btn-card" icon>
            <v-icon color="#fff"> mdi-arrow-left</v-icon>
          </v-btn>
        </router-link>
      </transition>
      <transition name="bounce">
        <router-link
          v-if="flagIsfather == true && valueE1 == 2"
          class="flecha-back"
          :to="{ path: `/${this.nameIdC}`, params: { _id: this.nameIdC } }"
        >
          <v-btn class="btn-card" icon @click="_setE1(1)">
            <v-icon color="#fff"> mdi-arrow-left</v-icon>
          </v-btn>
        </router-link>
      </transition>

      <h4>{{ name }}</h4>
      <v-spacer></v-spacer>
    </v-container>
  </nav>
</template>

<script>
import descarga from "@/components/btnDescarga.vue";
import store from "@/store/index.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Nav",
  components: {
    descarga,
  },
  props: {
    ruta: {
      typeof: String,
    },
    name: {
      typeof: String,
    },
  },

  data: () => ({
    nameIdC: store.state.dataInfo.qrCode,
    rutashow: false,
    arrow_show: true,
    flagIsfather: true,
    flag: false,
  }),
  computed: {
    ...mapGetters(["infoData", "process", "descriptionHeader", "valueE1"]),
  },
  mounted() {
    if (store.state.dataInfo.qrCode) {
      this.nameIdC = store.state.dataInfo.qrCode;
    } else {
      this.nameIdC = this.ruta;
    }

    // this.OrderCount = store.state.RespalOrder.length;
  },
  methods: {
    ...mapActions(["_setE1"]),
  },

  watch: {
    $route(to, from) {
      // //// console.log(to);
      this.nameIdC = this.ruta;

      switch (to.name) {
        case "Home":
          this.flag = false;
          break;
        case "pageOtp":
          this.flag = false;
          break;
        case "tracking":
          this.flag = false;
          break;
        case "BusinessLanding":
          this.flag = false;
          break;

        default:
          this.flag = true;
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/main.sass";
.flecha-back {
  position: absolute;
  left: 10px;
  top: 7px;
}
.nav {
  z-index: 11;
  height: 60px;
  width: 100%;
  border-bottom: #f0f0f0 1px solid;

  display: flex;
  background-color: #ffffff;

  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  position: fixed;
}
.descar {
  width: auto;
}
.btn-card {
  background-color: #00cdbc !important;
  width: 45px !important;
  height: 45px !important;

  border-radius: 50%;
}
nav {
  z-index: 11;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
}
.router-link-active {
  text-decoration: none !important;
}
a {
  text-decoration: none !important;
}
</style>
