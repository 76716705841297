<template>
  <LandingBusiness :typeLayaut="infoData.qrFatherTypeLayout" v-if="isFather" />
  <div v-else>
    <Menu />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LandingBusiness from "@/components/LandingBusiness";
import Menu from "./menu.vue";

export default {
  name: "Home",
  components: {
    LandingBusiness,
    Menu,
  },
  data() {
    return {};
  },
  async mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters(["infoData"]),
    isFather() {
      return this.infoData.qrIsFather ? true : false;
    },
  },
};
</script>
