<template>
  <v-card class="mx-auto border-b rounded-lg" max-width="600" v-ripple flat>
    <v-list-item three-line v-if="dataProduct">
      <v-list-item-content>
        <div class="discount" v-if="dataProduct.isDiscount == true">
          <!-- <p> <b></b> </p> -->
          <v-chip class="ma-2" color="#00cdbc" text-color="white">
            <b> -{{ Discount }}</b
            ><v-icon small> mdi-percent</v-icon></v-chip
          >
        </div>
        <v-list-item-title class="font-weight-medium" v-if="dataProduct.name"
          >{{ dataProduct.name }}
        </v-list-item-title>

        <v-list-item-subtitle
          class="font-weight-light"
          v-if="dataProduct.name"
          >{{ dataProduct.description }}</v-list-item-subtitle
        >

        <v-list-item-title
          :style="`color: ${color};`"
          class="text-h6 font-weight-light"
        >
          <b v-if="dataProduct.isDiscount == true">{{
            dataProduct.discountPrice | currency
          }}</b>

          <b
            :class="[
              dataProduct.isDiscount == true
                ? 'text-decoration-line-through ml-2 descont_true'
                : '',
            ]"
            >{{ dataProduct.price | currency }}
          </b>

          <v-chip
            dark
            color="#ff0000"
            small
            class="agotado_text"
            v-if="dataProduct.isAvailable == false"
          >
            <b>Agotado</b>
          </v-chip>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar
        v-if="dataProduct.images[0]"
        tile
        class="rounded-lg img-product"
        color="#f2f2f2"
        size="100"
      >
        <!-- <div class="rounded-lg is-Not-avaible" v-if="dataProduct.isAvailable == false">
          <h5>Agotado</h5>
        </div> -->
        <v-img
          v-if="dataProduct.images[0]"
          :src="dataProduct.images[0]"
          lazy-src="@/assets/preloader-02.svg"
          class="rounded-lg"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
            <div class="isAvailable"></div> </template
        ></v-img>
        <v-img
          v-else
          :src="dataProduct.images[0]"
          lazy-src="@/assets/preloader-02.svg"
          class="rounded-lg"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
            <div class="isAvailable"></div> </template
        ></v-img>
      </v-list-item-avatar>
    </v-list-item>
    <v-list-item three-line v-else>
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">
          <div
            class="skeleton-blank"
            style="width: calc(100% - 70px); height: 30px !important"
          ></div>
        </v-list-item-title>

        <v-list-item-subtitle class="font-weight-light">
          <div class="skeleton-blank" style="height: 15px !important"></div
        ></v-list-item-subtitle>

        <v-list-item-title
          :style="`color: ${color};`"
          class="text-h6 font-weight-light"
        >
          <b>
            <div
              class="skeleton-blank"
              style="width: 70px !important; height: 30px !important"
            ></div
          ></b>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        class="rounded-lg img-product"
        color="#f2f2f2"
        size="100"
      >
        <!-- <div class="rounded-lg is-Not-avaible" v-if="dataProduct.isAvailable == false">
          <h5>Agotado</h5>
        </div> -->
        <div class="skeleton-blank"></div>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "ProductList",
  props: ["dataProduct", "titulo", "label", "color"],

  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },

  data: () => ({
    Discount: 0,
  }),
  mounted: function () {
    if (this.dataProduct && this.dataProduct.isDiscount == true) {
      this.Discount = parseFloat(
        100 - (this.dataProduct.discountPrice / this.dataProduct.price) * 100
      );

      // parseFloat(this.priceList.amount / product.price * 100).toFixed(2)
    }
  },
  methods: {},
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/main.sass";

// $color-pack: true;
.agotado_text {
  background-color: #ff0000;
  margin-left: 5px;
  color: #fff;
}

.border-b {
  // border-bottom: 1px solid #efefef5d !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}
.descont_true {
  font-size: 16px;
  color: rgb(69, 69, 69) !important;
}
.discount {
  position: absolute;
  min-width: 60px;
  height: 25px;

  right: 0px;
  z-index: 2;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #fff;
    line-height: 0px;
    margin-bottom: 0px;
  }
}
.img-product {
  box-shadow: #00000024 0px 2px 10px;
}
.card_hover {
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: #f2f2f2 !important;
  }
}
.skeleton-blank {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: #f2f2f2;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
