<template>
  <v-app v-if="flag_landing">
    <Preloader />
    <Snackbar style="z-index: 1000 !important" />
    <Navbar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
  <v-app
    v-else
    class="bg-color"
    :style="
      qrBackgroundType
        ? `background-image:url(${imgBg});`
        : `background-color:${bgColor} !important;`
    "
  >
    <!-- <Preloader /> -->
    <Snackbar />
    <Nav :ruta="ruta" v-if="showNav" :color="color" :name="nameComeers" />
    <transition name="bouncebtn">
      <BtnShop v-if="orderCount > 0 && flag_ruta" :color="color" />
    </transition>
    <v-container class="wrap-secctions">
      <Header
        v-if="infoData.qrFatherTypeLayout != 'directoryForOrder'"
        :isStiky="isFather ? false : true"
      />
      <v-main class="hidden">
        <router-view />
      </v-main>
    </v-container>
    <Footer v-if="showFooter" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { db, fb } from "@/firebase";
import store from "@/store/index.js";
import BtnShop from "@/components/btn-shop";
import Preloader from "@/components/preloader";
import Snackbar from "@/components/snackbar";
import Navbar from "@/components/Landing/navbar.vue";
import Nav from "@/components/nav.vue";
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import DialogSuccess from "@/components/DialogSuccess";
import TransitionPage from "@/components/TransitionPage.vue";
export default {
  name: "App",
  components: {
    Footer,
    BtnShop,
    TransitionPage,
    Preloader,
    Snackbar,
    DialogSuccess,
    Navbar,
    Nav,
    Header,
  },

  data: () => ({
    successOrder: false,
    orderCount: 0,
    showNav: true,
    showFooter: true,
    color: "",
    loader: true,
    imgFav: require("@/assets/favi-compras.png"),
    nameComeers: null,
    addons: [],
    addonsData: [],
    description: "",
    items_selec: {},
    menu: [],
    ruta: "",
    flag_ruta: false,
    chipsGroup: [],
    flag_landing: false,
  }),
  computed: {
    ...mapGetters([
      "infoData",
      "get_pathQr",
      "CarShop",
      "dataClientOrder",
      "getOrderResponse",
    ]),
    isFather() {
      if (this.infoData.qrIsFather) {
        return true;
      } else {
        return false;
      }
    },
    sortedMenu: function () {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return this.menu.sort(compare);
    },
    imgBg() {
      return this.infoData.qrBackgroundImage;
    },
    qrBackgroundType() {
      return this.infoData.qrBackgroundType;
    },
    bgColor() {
      return this.infoData.qrBackgroundColor || "#00cdbc";
    },
    sortedChips: function () {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return this.chipsGroup.sort(compare);
    },
  },

  methods: {
    ...mapActions([
      "addData_info",
      "Add_Menu",
      "Add_ComersId",
      "Add_chipsMenu",
      "Add_AddonsData",
      "CleanData",
      "Alert_",
    ]),
    respalOrder_state(array) {
      array.forEach((element) => {
        if (element.business == store.state.dataInfo.id) {
          this.Alert_({
            text: "Tienes una orden sin procesar",
            timeout: 3000,
            btn_closed: true,
            icon: false,
            iconType: "",
            type: "primary",
          });
        } else {
          //// // console.log("no es de aqui");
          this.CleanData();
        }
      });
    },
    async RecargarInfo(id) {
      this.ruta = id;
      //// // console.debug(this.ruta);
      this.addData_info(id);
      await this.get_bussines(id);

      await this.getAddons(store.state.dataInfo.id);
      store.commit("AddComersId", store.state.dataInfo.id);
    },
    // llenado addonsData
    getAddons(id_bussines) {
      return new Promise((resolve, rejct) => {
        db.collection("qrAddons")
          .where("businessId", "==", id_bussines)
          .where("active", "==", true)
          .where("deleted", "==", false)
          .get()
          .then((e) => {
            this.addonsData = [];
            for (let i = 0; i < e.docs.length; i++) {
              const addons = e.docs[i].data();
              addons.id = e.docs[i].id;
              // if (addons.required && addons.position) {
              //   addons.position = addons.position - 1;
              // }
              // if (addons.required && addons.position > 1) {
              //   addons.position = 1;
              // }
              this.addonsData.push(addons);
            }
            this.Add_AddonsData(this.addonsData);
            resolve(e.docs);
          });
      });
    },
    get_product(menu_id) {
      return new Promise((resolve, rejct) => {
        db.collection("qrProducts")
          .where("menu", "array-contains", menu_id)
          .where("active", "==", true)
          .where("deleted", "==", false)
          .get()
          .then((e) => {
            resolve(e.docs);
          });
      });
    },
    get_menu(id_bussines) {
      db.collection("businesses")
        .doc(id_bussines)
        .collection("qrMenu")
        .where("active", "==", true)
        .where("deleted", "==", false)
        .get()
        .then(async (e) => {
          this.menu = [];
          this.chipsGroup = [];
          await this.Add_Menu([]);
          await this.Add_chipsMenu([]);

          for (let index = 0; index < e.docs.length; index++) {
            const menus = e.docs[index];

            var groupProduct = menus.data();

            groupProduct.id = menus.id;
           
            var res = await this.get_product(groupProduct.id);

            var productos = [];
            for (let p = 0; p < res.length; p++) {
              const produc = res[p];
              var product_menu = produc.data();
              product_menu.id = produc.id;
              // console.debug(product_menu.id, produc.data().name);
              productos.push(product_menu);
            }
            groupProduct.products = productos;

            if (
              groupProduct.active == true &&
              groupProduct.isAvailable == true &&
              groupProduct.deleted == false &&
              groupProduct.products.length > 0
            ) {
              var obj = {
                id: groupProduct.id,
                name: groupProduct.name,
                position: groupProduct.position,
              };
              await this.chipsGroup.push(obj);
              await this.menu.push(groupProduct);
              // // console.debug(this.menu);
              // console.debug("__________________________________");
            }
          }
          await this.short_Menu_Product();
          await this.Add_chipsMenu(this.sortedChips);
          // //// // console.debug("cambiooo");
        });
      // console.debug(this.menu);
    },
    async short_Menu_Product() {
      var Array_Pro = this.sortedMenu;
      for (let index = 0; index < Array_Pro.length; index++) {

        const element = Array_Pro[index].products;
        console.debug(element.length)

        function compare(a, b) {
          if (a.isAvailable) {
          } else {
            a.position = element.length;
          }
          if (a.position < b.position) return -1;
          if (a.position > b.position) return 1;
          if (a.position == "") return 1;
          if (!a.position) return 1;
        }

        Array_Pro[index].products = element.sort(compare);
      }

      await this.Add_Menu(Array_Pro);
      // //// // console.debug(Array_Pro);
    },
    get_bussines(bussines) {
      return new Promise((resolve, rejct) => {
        db.collection("businesses")
          .where("qrCode", "==", bussines)
          .where("qrActive", "==", true)
          // .get()
          .onSnapshot((a) => {
            // Custon data

            a.docs.forEach((element, index) => {
              // console.debug(index);
              var datos = element.data();
              datos.id = element.id;
              this.menu = [];
              this.addonsData = [];
              this.chipsGroup = [];
              this.addData_info(datos);

              if (datos.qrIsVip && this.dataClientOrder.userPhone == "") {
                this.$router.push({
                  path: `/${this.$route.params._id}/number`,
                });
              }
              if (!datos.qrIsFather) {
                this.get_menu(element.id);
                // console.debug("sas");
              }
              this.nameComeers = datos.shortName;
              this.color = datos.qrThemeColor;
            });
            resolve(a.docs);
            rejct(false);
          });
      });
    },
    CalcuCarShop() {
      this.orderCount = 0;
      var cont = this.CarShop;
      cont.forEach((resp) => {
        this.orderCount = this.orderCount + resp.orderP.quantity;
      });
    },
  },
  watch: {
    getOrderResponse(e) {
      if (e.length >= 0) {
        this.$router.push({
          path: `/successOrder/${this.$route.params._id}`,
        });
        // // console.debug("successOrder", this.successOrder);
      }
    },
    $route(to, e) {
      this.ruta = to.params._id;
      let rutaValue = {
        Home: () => {
          this.flag_ruta = true;
          this.showNav = true;
          this.showFooter = true;
          this.flag_landing = false;
          this.CalcuCarShop();
        },
        ShoppingCart: () => {
          this.flag_ruta = false;
          this.showNav = true;
          this.showFooter = true;
        },
        planes: () => {
          this.flag_landing = true;
          this.showNav = false;
          this.showFooter = false;
        },
        tracking: () => {
          this.flag_landing = false;
          this.flag_ruta = false;
          this.showNav = false;
          this.showFooter = false;
        },
        pageOtp: () => {
          this.flag_ruta = false;
        },
        landing: () => {
          this.flag_landing = true;
        },
        BusinessLanding: () => {
          this.flag_landing = false;
          this.flag_ruta = false;
        },
      };
      rutaValue && to.name ? rutaValue[to.name]() : (this.flag_landing = false);
    },
    // recarga la info si Refrescaron la web desde la pantalla de carrito
    get_pathQr(idPath) {
      this.RecargarInfo(idPath);
      // // console.debug("idPath", idPath);
    },
    CarShop(val) {
      this.orderCount = 0;
      this.CalcuCarShop();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      var getDomain = document.domain;
      var search = getDomain.search("compra");
      if (search != -1) {
        const t = document.getElementById("title");
        const favicon = document.getElementById("favicon");
        t.innerHTML = "Compras hn";
        favicon.href = require("@/assets/favi-compras.png");
        this.$vuetify.theme.themes.dark.primary = "#FFC634";
        this.$vuetify.theme.themes.light.primary = "#FFC634";
      }
      // // console.debug(process.env.VUE_APP_EL_OCHO);
      if (this.$route.name == "landing" || this.$route.name == "planes") {
        this.flag_landing = true;
      }
      if (this.$route.name == "Home") {
        this.flag_ruta = true;
        this.flag_landing = false;
      } else {
        this.flag_ruta = false;
        this.flag_landing = false;
      }
      if (this.$route.name == "BusinessLanding") {
        this.flag_ruta = false;
      }

      if (this.$route.params._id) {
        this.ruta = this.$route.params._id;
        var _id = this.$route.params._id;
        await this.get_bussines(_id);
        await this.getAddons(store.state.dataInfo.id);

        this.Add_ComersId(store.state.dataInfo.id);
        // calculo de subtotal y si tiene ordenes pendientes
        var cont = this.CarShop;
        this.CalcuCarShop();
        this.respalOrder_state(cont);
      }
    });
  },
};
</script>
<style lang="scss" >
@import "@/_responsive.scss";
@import "@/main.scss";
</style>
