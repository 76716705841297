<template>
  <v-card >

    <v-img :src="Active"></v-img>

    <v-row>
      <v-col cols="12" class="flex-center" v-if="data.length > 2">
        <v-col
          class="btn-imhg"
          v-for="(i, index) in data"
          :key="index"
          cols="4"
        >
          <v-img
            class="img-btn"
            :class="{ active_img: Active === i }"
            max-width="100px"
            @click="Active = i"
            :src="i"
          ></v-img>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "expand_image",
  components: {},
  props: {
    dialog: {
      typeof: Boolean,
      default: false,
    },
    data: {
      typeof: [Object, Array],
    },
    color: {
      typeof: String,
    },
  },
  data() {
    return {
      Active: this.data[0],
      expand: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/main.scss";
@import "@/_responsive.scss";
.darx {
  background-color: transparent;
}
.flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row !important ;
  flex-wrap: nowrap !important;
}
.active_img {
  border: 3px solid #00cdbc !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.213);
  transform: translateY(-5px);
}
.btn-imhg {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .img-btn {
    width: 100px;
    border: 2px solid #ffffff;
    transition: 0.3s ease-in-out;
    border-radius: 15px !important;
  }
}
</style>
