<template>
  <transition name="fade">
    <div
      v-if="show"
      class="loader"
      :class="{ 'color-bg-compras': !isMenu, 'color-bg-menu': isMenu }"
      :style="`background-color: ${colorBG};`"
    >
      <div class="lottie-content">
        <!-- <img src="@/assets/logo-preloader.svg" alt="" srcset=""> -->
        <lottie v-if="show_lottie" :options="defaultOptions" />
        <!-- v-on:animCreated="handleAnimation" -->
      </div>
      <img
        src="@/assets/byocho.svg"
        class="by-ocho"
        width="100px"
        alt=""
        srcset=""
      />
    </div>
    <div
      v-if="show_progress"
      class="loader"
      :style="`background-color: ${colorBG};`"
    >
      <v-progress-circular
        :size="50"
        :color="color"
        indeterminate
      ></v-progress-circular>
    </div>
  </transition>
</template>

<script>
import Lottie from "./Lottie";
import { mapGetters } from "vuex";
// import store from "@/store/index.js";
import * as preloader from "@/assets/Lottie/preloader.json";
import * as compras from "@/assets/Lottie/compras.json";
// import * as ocho from "@/assets/Lottie/data.json";
// import * as Caminanding from "@/assets/Lottie/caminando.json";
// import * as deleteOrder from "@/assets/Lottie/delete.json";

export default {
  name: "Preloader",
  components: {
    lottie: Lottie,
  },
  props: {},
  data() {
    return {
      colorBG: "#00cdbc",
      color: "",
      isMenu: true,
      anim: null,
      show_progress: false,
      animationSpeed: 0,
      defaultOptions: null,
      show: true,
      show_lottie: false,
    };
  },
  mounted() {
    this.$nextTick(()=>{

      var getDomain = document.domain;
      var search = getDomain.search("compra");
  
      if (search != -1) {
        this.isMenu = false;
        this.defaultOptions = {
          animationData: compras.default,
          loop: false,
          autoplay: true,
          lenguaje: "",
        };
      } else {
        this.isMenu = true;
        this.defaultOptions = {
          animationData: preloader.default,
          loop: false,
          autoplay: true,
          lenguaje: "",
        };
      }
      setTimeout(() => {
        this.show_lottie = true;
        this.showLoader();
      }, 500);
    })
  },

  computed: mapGetters(["cantOrder", "process"]),
  watch: {
    // process(s) {
    //   this.colorBG = "#ffffffb5";
    //    this.color= store.state.dataInfo.qrThemeColor;
    //   // this.defaultOptions.animationData = deleteOrder.default;
    //   this.show_progress = true;
    //   setTimeout(() => {
    //     this.show_progress = false;
    //   }, 9000);
    // },
  },
  methods: {
    showLoader() {
      window.onload = setTimeout(() => {
        this.show = false;
      }, 3000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
$BG: #00cdbc;

@import "@/_responsive.scss";

.loader {
  width: 100vw;
  height: 100vh;
  // background-color: $BG;

  z-index: 1000 !important;
  display: flex;
  pointer-events: none;
  //  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  position: fixed;
  flex-direction: column;
  .lottie-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
  }
}
.color-bg-menu {
  background-image: radial-gradient(
    circle at 50% 50%,
    #00cdbc,
    #00c3cd
  ) !important;
}
.color-bg-compras {
  background-image: radial-gradient(
    circle at 50% 50%,
    #ffc634,
    #ffda7c
  ) !important;
}
@include responsive(mobile) {
  .lottie-content {
    width: 75% !important;

  }
}
@include responsive(desktop) {
  .lottie-content {
    width: 250px !important;
    // height: 100px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.by-ocho {
  position: absolute;
  bottom: 15vh;
}
</style>