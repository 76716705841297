<template>
  <div class="" :class="{ controler: true }">
    <div class="control">
      <!-- checkbox -->
      <div v-if="type == 'checkbox'" style="width: 100%">
        <v-checkbox
          class="wrap-form-selec check text-addons"
          v-for="(n, index) in Datos"
          :key="index"
          hide-details
          :required="Required"
          :rules="Required ? [(c) => c.length != 0 || 'Obligatorio'] : []"
          :color="color"
          :class="{ none_delete: n.deleted == true }"
          :disabled="
            n.active == false ||
            n.originalProduct.isAvailable == false ||
            n.deleted == true
          "
          v-model="selected"
          :value="n"
        >
          <template v-slot:label>
            <div
              class="label-select-form text-caption"
              :class="
                radioGroup == n ? 'font-weight-medium' : 'font-weight-light'
              "
              :style="`color:${selected ? '#6c6c6c' : colorAlertSpin};`"
            >
              {{ n.originalProduct.name }}
              <strong :style="`color:${color};`" class="text-caption label-Price"
                >+{{ n.price | currency }}</strong
              >
            </div>
          </template>
        </v-checkbox>
      </div>

      <!-- radio -->

      <div v-if="type == 'radio' && loading" class="wrap-radio">
        <div
          v-for="(n, index) in sortedArray"
          :key="index"
          class="wrap-form-selec my-1 text-addons"
          @click="(n.calValue = uncheck(n)), addos_id, type"
        >
          <div
            class="label-select-form text-caption label-addons"
            :class="radio == n ? 'font-weight-medium' : 'font-weight-light'"
            :style="`color:${radio ? '#6c6c6c' : colorAlertSpin};`"
          >
            {{ n.originalProduct.name }}
            <strong :style="`color:${color};`" class="text-caption label-Price"
              >+{{ n.price | currency }}</strong
            >
          </div>
          <div
            v-if="valueEdit"
            class="radio-control"
            :style="`background-color:${
              radio.idDoc == n.idDoc ? color : '#f2f2f2'
            }`"
          >
            <v-icon x-small dark v-if="radio.idDoc == n.idDoc"
              >fa-solid fa-check</v-icon
            >
          </div>
          <div
            v-else
            class="radio-control"
            :style="`background-color:${radio == n ? color : '#f2f2f2'}`"
          >
            <v-icon x-small dark v-if="radio == n">fa-solid fa-check</v-icon>
          </div>
        </div>

        <v-checkbox
          v-model="radio"
          :required="Required"
          disabled
          class="check-oculto"
          :rules="Required ? [(v) => !!v || 'Obligatorio'] : []"
        ></v-checkbox>
      </div>

      <!-- Spin -->
      <div v-if="type == 'spin'" style="width: 100%">
        <div class="text-caption text-left" style="display: flex" width="100px">
          <p :style="`color:${colorAlert}`" v-if="min != 0">(Min: {{ min }})</p>
          <p :style="`color:${colorAlert}`">(Max: {{ max }})</p>
          <v-checkbox
            v-model="spinValid"
            :required="Required"
            disabled
            class="check-oculto text-addons"
            :rules="Required ? [(v) => !!v || 'Obligatorio'] : []"
          ></v-checkbox>
        </div>

        <div
          class="sumar_resta m-2 wrap-form-selec"
          v-for="(spin, index) in spines"
          :key="index"
          :class="{
            none_delete: spin.deleted == true,
            disabled_spin:
              spin.active == false || spin.originalProduct.isAvailable == false,
          }"
        >
          <div
            class="label-select-form text-caption text-addons label-addons"
            :style="`color:${spinValid ? '#6c6c6c' : colorAlertSpin};`"
          >
            {{ spin.originalProduct.name }}

            <strong :style="`color:${color};`" class="text-caption label-Price"
              >+{{ spin.price | currency }}</strong
            >
          </div>

          <div class="wrap-spin-controler">
            <v-btn
              icon
              small
              outlined
              depressed
              @click="
                pinResul > 0 && spin.resul > 0
                  ? (spin.resul--, pinResul--)
                  : spin.resul
              "
            >
              <v-icon small> mdi-minus </v-icon>
            </v-btn>
            <div class="label">
              <b>{{ pinResul == spin.resul ? pinResul : spin.resul }}</b>
            </div>
            <v-btn
              :color="color"
              small
              outlined
              icon
              @click="pinResul < max ? (spin.resul++, pinResul++) : spin.resul"
            >
              <v-icon small> mdi-plus </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "@/store/index.js";
export default {
  name: "Controler",
  props: [
    "valueEdit",
    "addos_id",
    "Required",
    "VControler",
    "max",
    "min",
    "type",
    "color",
    "datos",
  ],
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },

  data: () => ({
    Datos: [],
    selected: [],
    SelectedRule: [(v) => !!v || "Name is required"],
    spines: [],
    spinValid: false,
    radio: null,
    pinResul: 0,
    producSend: null,
    spin: 0,
    colorAlert: "#6c6c6c",

    radioGroup: 1,
    data: [],
    alert_danger: false,
    resul: 0,
    loading: false,
  }),

  methods: {
    ...mapActions(["addAddons"]),
    uncheck(val) {
      if (val == this.radio && !this.Required) {
        this.radio = null;
        // console.debug(val.price);
        return val.price;
      } else {
        // console.debug(val.price);
        this.radio = val;
        return val.price;
      }
    },
    Calcu(s) {
      if (s == "+" && this.pinResul <= this.max) {
        this.pinResul++;
      }
      if (s == "-") {
        this.pinResul--;
      }
    },
  },
  computed: {
    ...mapState(["cantOrder"]),
    colorAlertSpin() {
      if (this.VControler && this.Required) {
        return "#6c6c6c";
      }
      if (!this.VControler && this.Required) {
        return "#ff5252";
      }
      if (!this.VControler && !this.Required) {
        return "#6c6c6c";
      }
    },
    sortedArray() {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return this.datos.sort(compare);
    },
  },
  watch: {
    radio(s) {
      if (s) {
        if (s.overridePrice == false) {
          this.resul = s.originalProduct.price;
        } else {
          this.resul = s.price;
        }
        this.$emit("getResul", this.resul);
        this.$emit("respalvalue", s);
      } else {
        this.$emit("getResul");
        this.$emit("respalvalue", null);
      }
      this.$emit("reCalcu");
    },
    selected(sele) {
      this.resul = 0;
      sele.forEach((elem) => {
        if (elem.overridePrice == false) {
          this.resul = this.resul + elem.originalProduct.price;
        } else {
          this.resul = this.resul + elem.price;
        }
      });
      this.$emit("getResul", this.resul);
      this.$emit("respalvalue", sele);
      this.$emit("reCalcu");
    },
    pinResul(p) {
      var catn = 0;
      var resp = 0;
      this.spines.forEach((element) => {
        if (element.price == 0) {
        } else {
          resp = resp + element.price * element.resul;
        }
        catn = catn + element.resul;
      });
      if (catn <= this.max && catn >= this.min) {
        this.colorAlert = "#4caf50";
        this.spinValid = true;
        // #4caf50
      } else {
        this.spinValid = false;
      }

      this.resul = resp;
      this.$emit("getResul", this.resul);
      this.$emit("reCalcu");
      this.$emit("respalvalue", p);
    },
  },

  mounted() {
    if (this.valueEdit && this.type == "checkbox") {
      this.selected = this.valueEdit;
    }
    if (this.valueEdit && this.type == "spin") {
      this.pinResul = this.valueEdit;
    }
    if (this.valueEdit && this.type == "radio") {
      this.radio = this.valueEdit;
    }
    if (this.valueEdit) {
      this.spinValid = true;
    }
    if (this.type == "spin") {
      this.spines = Object.assign([], this.sortedArray);
    }
    this.Datos = this.sortedArray;
    this.loading = true;
  },
};
</script>

<style lang="scss">
.wrap-radio {
  width: 100%;
  height: auto;
}
.none_delete {
  display: none !important;
}
.disabled_spin {
  opacity: 0.5;
}
.check-oculto {
  opacity: 0px;
  height: 0px !important;
  overflow: hidden;
}
.radio-layaut {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.price {
  margin-bottom: 0px;
}
.alert_danger {
  background-color: rgba(255, 0, 0, 0.726);
  border-radius: 1em;
  color: #fff !important;
}
.label-select-form {
  width: 100%;
  display: flex;
  justify-content: space-between;

  padding-right: 10px;
}
.label {
  font-size: 15px !important;
}
.check {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse !important;
}
.controler {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding-left: 10px;
  border-radius: 1em;
  transition: 0.3s ease-in-out;

  .text-p {
    width: 90%;

    height: auto;
    display: flex;

    justify-content: space-between;
    align-items: center;
    p {
      padding-left: 0px;
      font-size: 14px;
    }
  }
  .control {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: space-between;
    padding-top: 0px;
    align-items: flex-start;
    flex-direction: column;

    .wrap-form-selec {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .heckbox {
      transform: scale(1.2, 1.2);
    }
  }
}

.sumar_resta {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;

  .wrap-spin-controler {
    width: 100px;
    display: flex;
    align-items: center;

    justify-content: space-between;
  }
}
.v-input__slot {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.radio-control {
  width: 28px;
  height: 26px;
  border-radius: 50%;
  margin-left: 5px;
  background-color: rgb(248, 248, 248);
  display: flex;
  justify-content: center;
  border: solid 1px #f2f2f2;
}
.v-application .text-caption {
  font-size: 1rem !important;
  font-weight: 400;
}
.label-addons {
  text-align: justify !important;
}
.label-Price {
  white-space: nowrap !important;
  display: flex;
  flex-wrap: nowrap;justify-content: flex-end;
  align-content: center;
  align-items: center;
}
</style>
