

<template>
  <!-- v-if="bt_descarga" -->

  <v-dialog         transition="dialog-bottom-transition"
 v-model="dialog_btn" class="dialogo" width="300">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="bt_descarga == true"
        depressed
  
        :class="className"
        @click="descargar"
      >
        <!-- @click="descargar" -->
        <b class="descarga"> <slot name="pulpito"></slot> Descarga la App </b>
      </v-btn>
      <v-btn v-else depressed  :class="className" v-bind="attrs" v-on="on">
        <!-- @click="descargar" -->
        <b class="descarga"> <slot name="pulpito"></slot> Descarga la App </b>
      </v-btn>
    </template>

    <div class="code_qr" v-if="dialog_btn ==true" >
      <img
        src="@/assets/qr_img.png"
        class="code_qr"
        width="100%"
        alt=""
        srcset=""
      />
    </div>
  </v-dialog>
</template>
<script>
import store from "@/store/index.js";
export default {
  name: "Descarga",
  props: {
    className: String,
    text: String,
  },

  data() {
    // this.$i18n.locale = store.state.activo;
    return {
      dialog_btn: false,
      bt_descarga: screen.width <= 800 ? true : false,
      text_btn: store.state.activo,
    };
  },
  methods: {
    descargar: function () {
      if (navigator.platform === "iPhone") {
        window.location.href =
          "https://apps.apple.com/hn/app/ocho/id1471212294";
      } else {
        window.location.href =
          "https://play.google.com/store/apps/details?id=io.beanar.ocho&hl=es_419&gl=US";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.descarga {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn{
  height: 40px !important;
}
.btn-dark {
  background-color: white;
  border: solid #ff5900 2px;
  border-radius: 15px !important;
  box-shadow: #ff590094 0px 5px 10px !important;
}
.code_qr {
  border-radius: 2em !important;
  overflow: hidden;
  height: 100%;
}
.dialogo {
  background-color: #ff5900;
  border-radius: 2em !important;
}
.v-dialog__content {
    background-color: rgb(0 0 0 / 0%) !important;
}


</style>

