<template>
  <div>
    <v-dialog
      v-model="dialogPromo"
      v-if="dialogPromo"
      max-width="400px"
      max-height="400px"
      transition="dialog-bottom-transition"
    >
      <v-card
        class="modalPromoStyle"
        max-width="400px"
        max-height="400px"
        @click="dialogPromo = false"
      >
        <img
          :src="dataPromo.imgAds"
          height="100%"
          width="100%"
          alt=""
          srcset=""
        />
      </v-card>
    </v-dialog>

    <div class="wrap-content-home mt-6 pb-12">
      <v-data-iterator
        :items="menuData"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        loading
        hide-default-footer
        no-data-text="⏳ Cargando datos..."
        no-results-text=""
      >
        <template v-slot:header>
          <v-sheet elevation="0" class="sticky border-bdj" style="width: 100%">
            <v-row class="py-0 px-2">
              <v-col class="pa-1" cols="9" v-if="!showSearch">
                <!-- <v-chip-group :active-class="color" :color="color">
                  <v-chip
                    v-for="tag in get_chips"
                    :key="tag.id"
                    @click="scrollYm(tag.id)"
                  >
                    {{ tag.name }}
                  </v-chip>
                </v-chip-group> -->
                <v-select
                  filled
                  :items="get_chips"
                  rounded
                  v-model="valueSelect"
                  item-text="name"
                  :color="color"
                  item-value="id"
                  hide-details
                  @change="scrollYm"
                  dense
                ></v-select>
                <!-- @change="scrollYm" -->
              </v-col>
              <v-col class="pa-1" :cols="showSearch ? '12' : '3'">
                <v-text-field
                  :color="color"
                  v-model="search"
                  :placeholder="showSearch ? 'Buscar' : ''"
                  filled
                  style="padding: 0 5px !important"
                  rounded
                  @focus="onFocus"
                  hide-details
                  @keypress="onFocus"
                  append-icon="mdi-magnify"
                  dense
                  :append-outer-icon="showSearch ? 'mdi-close-circle' : null"
                  @click:append-outer="disableSearch"
                >
                </v-text-field>
                <!-- <v-btn v-else fab elevation="0" small color="#f0f0f0" light @click="onFocus">
                  <v-icon color="#000">mdi-magnify</v-icon>
                </v-btn> -->
              </v-col>
            </v-row>
          </v-sheet>
        </template>

        <template v-slot:default="props">
          <div class="mt-12">
            <div v-if="search == ''">
              <CardTemplate
                v-for="(m, index) in props.items"
                :ref="m.id"
                :key="index"
                :id="m.id"
                :titulo="m.name"
                classe="titulo intersector"
                :colorChip="color"
                :chipActive="true"
                class=""
              >
                <v-row class="pt-2">
                  <v-col
                    md="6"
                    sm="6"
                    lg="4"
                    cols="12"
                    v-for="(i, index) in m.products"
                    :key="index"
                    @click="order(i, i.addons)"
                    class="py-0"
                    :class="{
                      noselect: i.isAvailable == false,
                      lineProductMobile: index < m.products.length - 1,
                    }"
                  >
                    <ProductList
                      :color="color"
                      :dataProduct="i"
                      class="product_list"
                    />
                  </v-col>
                </v-row>
              </CardTemplate>
            </div>

            <CardTemplate
              titulo="Menu"
              classe="titulo"
              v-if="menuData.length == 0"
            >
              <v-row>
                <v-col
                  md="6"
                  sm="6"
                  lg="4"
                  cols="12"
                  class="py-0"
                  v-for="(i, index) in [0, 1, 2, 3, 4, 5, 6, 7, 9, 10]"
                  :key="index"
                  :class="{ noselect: true, lineProductMobile: index < 10 - 1 }"
                >
                  <ProductList :color="color" class="product_list" />
                </v-col>
              </v-row>
            </CardTemplate>

            <CardTemplate
              id="Search"
              titulo="Resultado"
              classe="titulo"
              v-if="search != ''"
            >
              <v-row>
                <v-col
                  md="6"
                  sm="6"
                  lg="4"
                  cols="12"
                  class="py-0"
                  v-for="(i, index) in props.items"
                  :key="index"
                  @click="order(i, i.addons)"
                  :class="{
                    noselect: i.isAvailable == false,
                    lineProductMobile: index < props.items.length - 1,
                  }"
                >
                  <ProductList
                    :color="color"
                    :dataProduct="i"
                    class="product_list"
                  />
                </v-col>
              </v-row>
            </CardTemplate>
          </div>
        </template>

        <template v-slot:no-results>
          <div class="resultado-search">
            <img src="@/assets/no-result-01.svg" width="200px" height="200px" />
            <span> Busqueda sin resultados, favor intenta de nuevo </span>
          </div>
        </template>
        <template v-slot:loading class="mt-12">
          <div class="mt-12">
            <CardTemplate titulo="Cargando menu.." classe="titulo">
              <v-row>
                <v-col
                  md="6"
                  sm="6"
                  lg="4"
                  cols="12"
                  class="py-0"
                  v-for="(i, index) in [0, 1, 2, 3, 4, 5, 6]"
                  :key="index"
                  :class="{ noselect: true, lineProductMobile: index < 6 - 1 }"
                >
                  <ProductList :color="color" class="product_list" />
                </v-col>
              </v-row>
            </CardTemplate>
          </div>
        </template>
      </v-data-iterator>
    </div>
    <v-dialog
      v-model="dialog"
      v-if="dialog"
      :fullscreen="mobile"
      transition="dialog-bottom-transition"
      persistent
      max-width="500px"
    >
      <DialogMenu
        :color="color"
        :addonsProps="setAddonsModal"
        :dataProduct="setDataModal"
        :dialog="dialog"
        :id_product="setDataModal.id"
        v-if="dialog && setAddonsModal"
        @close="dialogClose"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardTemplate from "@/components/cardTemplate";
import Header from "@/components/header";
import LandingBusiness from "@/components/LandingBusiness";
import sectionsProduct from "@/components/sectionsProduct";
import Search from "@/components/Search";
import ProductList from "@/components/productList";
import DialogMenu from "@/components/dialogMenu";
import { db, fb } from "@/firebase";

export default {
  name: "Menu",
  props: ["showInfo"],
  components: {
    Header,
    LandingBusiness,
    CardTemplate,
    ProductList,
    DialogMenu,
    Search,
    sectionsProduct,
  },
  data() {
    return {
      valueSelect: null,
      datafromOrder: {},
      addons: [],
      dialogPromo: false,
      addonsData: [],
      mobile: screen.width <= 550 ? true : false,
      dataPromo: null,
      showSearch: false,
      menu: [],
      color: "",
      dialog: false,
      itemsPerPageArray: [10, 12, 14],
      search: "",
      sortDesc: false,
      page: 1,
      itemsPerPage: 50,
      isIntersecting: false,
      sortBy: ["position"],
      attrs: {
        boilerplate: true,
        elevation: 0,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0);
      this.addonsData = this.get_addonsData;
      this.color = this.infoData.qrThemeColor;
      this.intersect(5000);
    });
  },
  methods: {
    ...mapActions(["addData_info", "Alert_"]),

    getOffset(topAll) {
      var _y = 0;
      while (topAll && !isNaN(topAll.offsetTop)) {
        _y += topAll.offsetTop - topAll.scrollTop;
        topAll = topAll.offsetParent;
      }
      return { top: _y };
    },
    onFocus() {
      // this.search = "";
      this.showSearch = true;
    },
    disableSearch() {
      setTimeout(() => {
        this.showSearch = false;
        this.search = "";
        this.intersect(1000);
      }, 1000);
    },
    intersect(time) {
      setTimeout(() => {
        const boxes = document.querySelectorAll(".intersector");
        const callback = (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.valueSelect = entry.target.id;
            }
          });
        };
        const options = {
          threshold: 0,
          rootMargin: `0px 0px -${window.screen.height - 200}px 0px`,
        };
        console.debug(options);
        const observer = new IntersectionObserver(callback, options);
        boxes.forEach((element) => observer.observe(element));
      }, time);
    },
    // animacion scroll de chips
    scrollYm(item) {
      var topAll = this.$refs[item][0].$el;
      var y = this.getOffset(topAll).top;
      window.scroll({
        top: y - 150,
        left: 0,
        behavior: "smooth",
      });
    },
    dialogClose(s) {
      this.dialog = false;
    },
    order(data, addonsItems) {
      if (this.infoData.qrClosed || !this.infoData.qrIsOpen) {
        this.Alert_({
          text: "En este momento el comercio está cerrado.",
          timeout: 3000,
          btn_closed: true,
          icon: true,
          iconType: "mdi-alert",
          type: "error",
        });
      } else {
        this.datafromOrder = data;
        this.ItemAddons(addonsItems).then((s) => {
          this.dialog = true;
        });
      }
    },
    ItemAddons(addons) {
      return new Promise(async (resolve, rejct) => {
        var filters = this.addonsData.filter((e) => addons.includes(e.id));

        this.addons = filters;
        for (let a = 0; a < this.addons.length; a++) {
          const addonss = this.addons[a];

          const addonsArray = [];
          await db
            .collection("qrAddons")
            .doc(addonss.id)
            .collection("products").where('active','==',true).where('deleted','==',false)
            .get()
            .then((n) => {
              for (let r = 0; r < n.docs.length; r++) {
                const element = n.docs[r].data();
                element.idDoc = n.docs[r].id;

                addonsArray.push(element);
              }
            });
          this.addons[a].products = addonsArray;
        }

        resolve(this.addons);
      });
    },
    //  onIntersect (entries, observer) {
    //   // this.valueSelect = entries;
    //   console.debug(entries)
    //   // console.debug(observer)
    //     this.isIntersecting = entries[0].isIntersecting
    //   },
  },
  watch: {
    qrImgPromo(w) {
      this.dataPromo = this.qrImgPromo;
      if (this.dataPromo.active) {
        setTimeout(() => {
          this.dialogPromo = true;
        }, this.dataPromo.timeDelay);
      }
    },
    get_addonsData(val) {
      this.addonsData = val;
    },
    infoData(e) {
      this.color = e.qrThemeColor;

      this.addonsData = this.get_addonsData;
    },
  },
  computed: {
    ...mapGetters([
      "get_Menu_state",
      "get_addonsData",
      "get_chips",
      "infoData",
      "addonss",
    ]),
    qrImgPromo() {
      if (this.infoData.qrImgPromo) {
        return this.infoData.qrImgPromo;
      } else {
        return null;
      }
    },

    isFather() {
      return this.infoData.qrIsFather ? true : false;
    },
    setAddonsModal() {
      return Object.assign([], this.addons);
    },
    setDataModal() {
      return Object.assign([], this.datafromOrder);
    },
    menuData() {
      return this.search == "" ? this.get_Menu_state : this.menuListProduct;
    },
    menuListProduct() {
      var Filter = [];
      for (let index = 0; index < this.get_Menu_state.length; index++) {
        const element = this.get_Menu_state[index].products;

        for (let i = 0; i < element.length; i++) {
          const produ = element[i];
          Filter.push(produ);
        }
      }
      return Filter;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
.modalPromoStyle {
  border-radius: 15px;
  overflow: hidden;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky {
  position: -webkit-sticky;
  position: sticky !important;
  top: 60px;
  z-index: 3 !important;
}
.display_none {
  display: none !important;
}

.mobile-block {
  display: none !important;
}
@include responsive(mobile) {
  .mobile-block {
    display: block !important;
  }
}
.product_list {
  cursor: pointer;
}
.wrap-content-home {
  // margin-top: 70px !important;
  padding: 10px;

  @include responsive(desktop) {
    padding: 10px;
  }
  @include responsive(large) {
    padding: 0px 10px;
  }
}
.preloaders {
  display: flex;
  flex-direction: row;
}
.cta {
  width: 100%;
  text-align: center;
  a {
    color: #202e4f;
    text-decoration: underline !important;
  }
}
@include responsive(mobile) {
  .lineProductMobile {
    border-bottom: 1px solid #f2f2f2;
  }
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 5px !important;
}
.resultado-search {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #646464;
  padding: 0px 10px;
}
.border-bdj {
  border-bottom: #f2f2f2 1px solid;
  height: 50px;
}
.noselect {
  pointer-events: none !important;
}
</style>
