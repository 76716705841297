<template>
  <div class="navbar" ref="navbar">
    <v-container class="wrap-nav">
      <div class="logo_menu" @click="send_home">
        <svg
          v-if="!isCompras"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 357.91 123.47"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="letras-blancas"
                d="M75.19,67.88q-9.48,0-9.48,14.77v33.87a4.34,4.34,0,0,1-4.34,4.34H46.74a4.34,4.34,0,0,1-4.33-4.34v-34a22,22,0,0,0-2.24-10.43,7.61,7.61,0,0,0-7.25-4.2q-9.62,0-9.62,14.77v33.87A4.34,4.34,0,0,1,19,120.86H4.34A4.34,4.34,0,0,1,0,116.52V55a4.33,4.33,0,0,1,4.34-4.33H19A4.33,4.33,0,0,1,23.3,55h0a1.5,1.5,0,0,0,2.68.93q5.55-6.75,15.07-6.75,12.8,0,19,9.16a1.51,1.51,0,0,0,2.47.07q7-9.22,20.67-9.23A24.41,24.41,0,0,1,95.31,52,19.25,19.25,0,0,1,103,59.75a39.91,39.91,0,0,1,3.87,10.3,52.52,52.52,0,0,1,1.21,11.52v35a4.34,4.34,0,0,1-4.33,4.34H89.15a4.34,4.34,0,0,1-4.34-4.34v-34a21.54,21.54,0,0,0-2.3-10.43A7.76,7.76,0,0,0,75.19,67.88Z"
              />
              <path
                class="letras-blancas"
                d="M148.46,122.35q-19,0-30.07-10.37A34,34,0,0,1,107.28,86,35.49,35.49,0,0,1,118,59.82q10.7-10.64,28.45-10.63,16.53,0,27,10t10.43,28c0,.58,0,1.09,0,1.54a1.49,1.49,0,0,1-1.5,1.44H131.37a1.51,1.51,0,0,0-1.46,1.9q1,4.14,5.62,7.52,5.61,4.13,15,4.13a28.28,28.28,0,0,0,16.24-5.31,4.3,4.3,0,0,1,5.69.75c.85,1,1.9,2.22,3.13,3.68s2.15,2.53,3,3.48a4.34,4.34,0,0,1-.48,6.13Q166.36,122.34,148.46,122.35ZM146.3,66.53a20.61,20.61,0,0,0-10.5,2.77,11.68,11.68,0,0,0-5.34,6.3,1.51,1.51,0,0,0,1.41,2h28.28a1.49,1.49,0,0,0,1.47-1.77,9.87,9.87,0,0,0-4.82-6.57A20.19,20.19,0,0,0,146.3,66.53Z"
              />
              <path
                class="letras-blancas"
                d="M183.39,116.52V55a4.34,4.34,0,0,1,4.34-4.33h14.63A4.34,4.34,0,0,1,206.7,55v.52a1.5,1.5,0,0,0,2.58,1q6.79-7.38,18.28-7.38,24.8,0,24.79,32.38v35a4.34,4.34,0,0,1-4.33,4.34H233.39a4.34,4.34,0,0,1-4.34-4.34V82.92a20.43,20.43,0,0,0-2.64-10.57q-2.64-4.47-8.47-4.47t-8.53,4.2q-2.72,4.2-2.71,10.84v33.6a4.35,4.35,0,0,1-4.34,4.34H187.73A4.35,4.35,0,0,1,183.39,116.52Z"
              />
              <path
                class="letras-blancas"
                d="M338.53,59.75c-2.23-2.44-8.1-6.62-16.92-6.53l-1.18,0h-.18a22.46,22.46,0,0,0-9.06,2.42l-.64.35A22.77,22.77,0,0,0,306,59.31c-.33.31-.65.61-.94.91a24.54,24.54,0,0,0-3.5,4.58s0,0,0,0a29.7,29.7,0,0,0-2.89,6.86,34.57,34.57,0,0,0-1.14,11.6h0c.23,3.74-.31,8.51-3,11.55,0,0,0,0,0,0a8.85,8.85,0,0,1-4.33,3.38,12.12,12.12,0,0,1-4.32.71q-11.1,0-11.1-15V55a4.34,4.34,0,0,0-4.34-4.34H255.81A4.34,4.34,0,0,0,251.47,55V90.39a34.52,34.52,0,0,0,68.88,1.44c0-.44.06-.88.08-1.31V87.4a33.79,33.79,0,0,0-.58-5h0c-.06-.59-.14-1.14-.22-1.64h0a14.82,14.82,0,0,1,.8-7.75,14,14,0,0,1,.67-1.48,15.24,15.24,0,0,1,1.16-1.87,14.41,14.41,0,0,1,14.22-5.92,2.25,2.25,0,0,0,2.34-1.15A2.56,2.56,0,0,0,338.53,59.75ZM315.44,63.2c-.76,0-1.33-.47-1.33-.94a.55.55,0,0,1,.16-.39,1.74,1.74,0,0,1,2.44.12.57.57,0,0,1,.11.38C316.8,62.85,316.19,63.23,315.44,63.2Zm.95-3c-1.7.3-3.15-.31-3.4-1.42a1.43,1.43,0,0,1,.17-1,3.65,3.65,0,0,1,2.53-1.53l.3,0c1.56-.18,2.95.46,3.11,1.49a1.1,1.1,0,0,1,0,.18C319.17,58.88,318,59.92,316.39,60.24ZM332,60.19a.68.68,0,0,1,0,.14c-.44,2.1-3.37,3.22-6.56,2.52-2.59-.5-4.58-2-5-3.72a2.57,2.57,0,0,1,0-1.2s0-.11,0-.17a1.07,1.07,0,0,1,.06-.17c.67-1.88,3.42-2.78,6.45-2.18S332.31,58.13,332,60.19Z"
              />
              <path
                class="letras-azules"
                d="M353.11,50.48H291a5,5,0,0,1-5-4.37,4.81,4.81,0,0,1,4.79-5.24h62.14a5,5,0,0,1,5,4.37A4.82,4.82,0,0,1,353.11,50.48Z"
              />
              <path
                class="letras-azules"
                d="M309.23,22.79c0-2.15-.47-4.66-3.25-4.66s-3.19,2.51-3.19,4.66v12a3.1,3.1,0,0,1-3.11,3.1H295.1a3.1,3.1,0,0,1-3.1-3.1V3.11A3.1,3.1,0,0,1,295.1,0h4.58a3.11,3.11,0,0,1,3.11,3.11V13.72c1.52-2.46,3.87-4,7.65-4,8.59,0,9.58,7.49,9.58,12v13a3.1,3.1,0,0,1-3.1,3.1h-4.58a3.1,3.1,0,0,1-3.11-3.1Z"
              />
              <path
                class="letras-azules"
                d="M341,22.79c0-2.15-.47-4.66-3.24-4.66s-3.2,2.51-3.2,4.66v12a3.1,3.1,0,0,1-3.11,3.1h-4.57a3.1,3.1,0,0,1-3.11-3.1V13.43a3.11,3.11,0,0,1,3.11-3.11h4.57a3.11,3.11,0,0,1,3.11,3.11v.29c1.52-2.46,3.88-4,7.65-4,8.59,0,9.59,7.49,9.59,12v13a3.1,3.1,0,0,1-3.11,3.1h-4.58a3.11,3.11,0,0,1-3.11-3.1Z"
              />
            </g>
          </g>
        </svg>
       

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 731.51 157.39"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="U">
              <rect
                class="cls-1"
                x="619.75"
                y="34.39"
                width="111.64"
                height="101.32"
                rx="12"
                transform="translate(-0.24 1.94) rotate(-0.16)"
              />
              <path
                class="cls-2 "
                d="M709.68,111.59l-66.74.19a5.37,5.37,0,0,1-5.38-4.68,5.16,5.16,0,0,1,4.67-5.62,3.46,3.46,0,0,1,.46,0l66.77-.2a5.37,5.37,0,0,1,5.38,4.68,5.18,5.18,0,0,1-4.69,5.63Z"
              />
              <path
                class="cls-2"
                d="M662.45,82c0-2.31-.52-5-3.51-5s-3.42,2.7-3.41,5l0,12.9a3.33,3.33,0,0,1-3.32,3.34h-4.93A3.33,3.33,0,0,1,644,94.92h0l-.1-34a3.33,3.33,0,0,1,3.31-3.35h0l4.92,0a3.36,3.36,0,0,1,3.36,3.34l0,11.4c1.62-2.65,4.14-4.31,8.21-4.32,9.22,0,10.31,8,10.33,12.86l0,14a3.34,3.34,0,0,1-3.33,3.34h-4.92a3.33,3.33,0,0,1-3.35-3.31h0Z"
              />
              <path
                class="cls-2"
                d="M696.58,81.42c0-2.31-.52-5-3.5-5s-3.43,2.7-3.42,5l0,12.9a3.34,3.34,0,0,1-3.32,3.34h-4.92a3.34,3.34,0,0,1-3.36-3.31h0l-.06-23a3.34,3.34,0,0,1,3.33-3.35h4.91a3.33,3.33,0,0,1,3.35,3.33v.31c1.63-2.65,4.16-4.31,8.21-4.32,9.23,0,10.33,8,10.34,12.86l0,14a3.33,3.33,0,0,1-3.32,3.34H700a3.34,3.34,0,0,1-3.35-3.32Z"
              />
              <path
                class="cls-2"
                d="M643.88,44.4h0a4,4,0,0,1-4-4,55.82,55.82,0,0,1,4.41-19.74C650.18,7.18,660.94,0,675.4,0h.13c14.35,0,25.26,7,31.56,20.37a56.74,56.74,0,0,1,5,19.68,4,4,0,1,1-8,.34C704.08,39.07,702.35,8,675.52,8h-.1c-26.93.08-27.53,31.12-27.54,32.44A4,4,0,0,1,643.88,44.4Z"
              />
              <path
                class="letras-blancas-compras"
                d="M602.28,53.89a2.14,2.14,0,0,0-2-1.22,1.88,1.88,0,0,0-1.7,1.36A12,12,0,0,1,589,62.72a12.39,12.39,0,0,1-1.82.22,10.26,10.26,0,0,1-1.36,0,12.59,12.59,0,0,1-4.41-1.13l-.4-.19a12.37,12.37,0,0,1-1.35-.81c-.35-.24-.74-.49-1.16-.74l-.3-.21a28.63,28.63,0,0,0-10.23-3,41.63,41.63,0,0,0-5.19-.3c-16,0-28.37,9.15-28.37,22.93,0,15.25,17.9,19.76,21.74,21.22l8,2.91c4.11,1.46,4.64,2.26,4.64,3.72,0,2.25-2.39,3.44-6.5,3.44-4.72,0-8.44-1.36-10.41-5.51a4,4,0,0,0-4.48-2.18l-12.5,2.83a4,4,0,0,0-3,5c3.74,12.55,14.49,18.33,30.36,18.33,16.31,0,30.36-7,30.36-22.54-.4-9.54-6.89-17-22.14-22.27l-7.69-3.18c-3.18-1.06-4.64-1.86-4.64-3.58s1.33-3.32,4.38-3.32a8.49,8.49,0,0,1,3.45.67,2.43,2.43,0,0,1,.37.16l.34.14.25.09a18.4,18.4,0,0,1,1.88.75c.4.19.79.39,1.15.59a28.39,28.39,0,0,0,4.62,2,29.05,29.05,0,0,0,4.58,1.18,24.43,24.43,0,0,0,6.22.23,21,21,0,0,0,4.7-1l1-.39a19.36,19.36,0,0,0,4.13-2.28l.49-.36a18.82,18.82,0,0,0,5-6l.48-1A19.3,19.3,0,0,0,602.28,53.89ZM592.1,71.52a.47.47,0,0,1-.35,0,1,1,0,0,1-.24-1.34c.24-.58.75-.91,1.12-.75a.47.47,0,0,1,.25.21A1.46,1.46,0,0,1,592.1,71.52Zm4.87.69-.11.22a3,3,0,0,1-2.05,1.38,1.2,1.2,0,0,1-.82-.22c-.75-.59-.7-1.9.13-3.08l0,0c.81-1.1,2-1.62,2.71-1.23l.14.06C597.66,69.81,597.66,71.09,597,72.21Zm4.51-8c-.62,2.51-2.27,4.27-3.93,4.12l-.15,0-.13-.06a2.3,2.3,0,0,1-.91-.42c-1.16-.93-1.59-3-1.05-5.11.59-2.66,2.48-4.49,4.22-4.08l.11,0C601.31,59.18,602.09,61.69,601.48,64.2Z"
              />
              <path
                class="letras-blancas-compras"
                d="M51.71,33.42c13.75,0,23.58,4.78,32.62,12.14a4,4,0,0,1,.51,5.76L74.51,63.13a4,4,0,0,1-5.66.34,25.81,25.81,0,0,0-17.14-6.18c-14.32,0-23.87,10.61-23.87,24s9.55,24,23.87,24A25.77,25.77,0,0,0,68.85,99.1a4,4,0,0,1,5.66.35l10.3,11.77a4,4,0,0,1-.54,5.78c-9,7.19-18.84,12.15-32.56,12.15C20.55,129.15,0,107.14,0,81.29S20.55,33.42,51.71,33.42Z"
              />
              <path
                class="letras-blancas-compras"
                d="M129.93,129.15c-23.87,0-40.31-16.18-40.31-36.33s16.44-36.33,40.31-36.33,40.31,16.18,40.31,36.33S153.79,129.15,129.93,129.15ZM128,107.84c9.59,1,16.92-5.34,16.92-15,0-9-6.36-15.11-15-15.11-9.4,0-15.68,7-14.92,16.68A14.66,14.66,0,0,0,128,107.84Z"
              />
              <path
                class="letras-blancas-compras"
                d="M259.86,88.71c0-5.43-1.06-10.87-6.36-10.87S247,83.28,247,88.71v35a4,4,0,0,1-4,4H223.69a4,4,0,0,1-4-4v-35c0-5.43-1.07-10.87-6.37-10.87s-6.49,5.44-6.49,10.87v35a4,4,0,0,1-4,4H183.51a4,4,0,0,1-4-4V62a4,4,0,0,1,4-4h19.32a4,4,0,0,1,4,4V66.7c2.51-6.23,8.61-10.21,16-10.21,9,0,14.85,4.25,18.43,9.95,7.42-8.75,13.79-9.95,21.34-9.95,19.36,0,24.53,19.23,24.53,30.5v36.7a4,4,0,0,1-4,4H263.86a4,4,0,0,1-4-4Z"
              />
              <path
                class="letras-blancas-compras"
                d="M326.55,62a1.57,1.57,0,0,0,2.67,1.13c4-4.15,10-6.59,17.21-6.59,18.57,0,32.75,15.25,32.75,36.33S365,129.15,346.43,129.15c-9,0-16.17-3.85-19.88-10.21v34.45a4,4,0,0,1-4,4H303.23a4,4,0,0,1-4-4V62a4,4,0,0,1,4-4h19.32a4,4,0,0,1,4,4ZM339,77.31c-8,0-12.86,6.63-12.86,15.51s4.9,15.51,12.86,15.51,12.86-6.63,12.86-15.51S347,77.31,339,77.31Z"
              />
              <path
                class="letras-blancas-compras"
                d="M415,66.1a1,1,0,0,0,1.93.45c4.5-8.67,14.59-10.06,17.69-10.06h.06a3.92,3.92,0,0,1,3.92,3.91V78.55a4,4,0,0,1-4.35,4c-1.88-.18-3.48-.18-4.4-.18-11.14,0-13.79,7.69-13.79,15v26.36a4,4,0,0,1-4,4H392.72a4,4,0,0,1-4-4V62a4,4,0,0,1,4-4H411a4,4,0,0,1,4,4Z"
              />
              <path
                class="letras-blancas-compras"
                d="M495.19,119.07c-3.72,6.23-10.87,10.08-19.89,10.08-18.56,0-32.75-15.25-32.75-36.33s14.19-36.33,32.75-36.33c9,0,16.17,3.85,19.89,10.21V62a4,4,0,0,1,4-4H518.5a4,4,0,0,1,4,4v61.74a4,4,0,0,1-4,4H499.19a4,4,0,0,1-4-4Zm-12.47-10.74c8,0,12.86-6.63,12.86-15.51s-4.9-15.51-12.86-15.51-12.86,6.63-12.86,15.51S474.77,108.33,482.72,108.33Z"
              />
            </g>
          </g>
        </svg>
      </div>
      <v-spacer> </v-spacer>

      <!-- <router-link :to="{ path: '/planes', name: 'planes' }">
        <span class="link-s">Planes</span>
      </router-link> -->

      <v-btn
        color="#fff"
        outlined
        class="px-12 btn-color-e"
        style="border-radius: 12px"
        :href="!isCompras ? 'https://mi.menu.hn' : 'https://mi.compras.hn'"
        target="_blank"
      >
        Ingresar
      </v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "navbar",
  components: {},
  props: {},
  data() {
    return {
      isCompras: false,
      scrolltops: 0,
    };
  },
  computed: {
    getScrollTop() {
      var scrolltop = this.$refs.navbar.scrollTop;
      this.scrolltops = scrolltop;

      return scrolltop;
    },
  },
  methods: {
    send_home() {
      this.$router.push("/");
    },
  },
  mounted() {
    var getDomain = document.domain;
    var search = getDomain.search("compra");
    if (search != -1) {
      this.isCompras = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
html[data-scroll="0"] {
  .link-s {
    font-weight: 800;
    margin-right: 20px;
    color: #ffffff !important;
  }
  .btn-color-e {
    color: #fff !important;
  }
  .navbar {
    background-color: transparent !important;
    .wrap-nav {
      .logo_menu {
        margin-top: 10px;
        width: 200px !important;

        cursor: pointer !important;
        @include responsive(mobile) {
          width: 120px !important;
          margin-left: 10px;
        }
      }
    }
  }
  .letras-blancas {
    fill: #fff;
  }
  .letras-blancas-compras {
  fill: #fff;
}
  .letras-azules {
    fill: #202e4f;
  }
}
.navbar {
  width: 100%;
  transition: 0.3s ease-in-out;

  background-color: #fff !important;

  height: 60px;
  position: fixed;
  z-index: 900;
  .wrap-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo_menu {
      margin-top: 0px;
      transition: 00.3s ease-in-out;
      width: 100px !important;
      cursor: pointer !important;
    }
  }
}
.letras-blancas {
  fill: #00cdbc;
}
.letras-blancas-compras {
  fill: #FFC634;
}
.letras-azules {
  fill: #202e4f;
}
.btn-color-e {
  color: #202e4f !important;
}
.link-s {
  font-weight: 500;
  margin-right: 20px;
  color: #202e4f !important;
  @include responsive(mobile) {
    display: none;
  }
}

.cls-1 {
  fill: #202e4f;
}
.cls-2 {
  fill: #fff;
}
</style>
