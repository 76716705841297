

<template>
  <div class="wrap-brn-shop">
    <!-- :class="direccion == true ? '_up_' : '_down_'" -->
    <router-link
      class="btn-car_shopping rounded-lg _up_"
      :style="`background-color:${color};`"
      :to="{
        path: `/${this.nameIdC}/shoppingCart`,
        params: { _id: this.nameIdC },
      }"
    >
      <v-badge :content="cant" :value="cant" color="#00cdbc" right overlap>
        <v-icon color="#fff" size="30px"> mdi-cart </v-icon>
      </v-badge>
      <div class="text_tal" :class="{ downMobile: direccion == false }">
        <h6>Subtotal</h6>
        <h3>{{ calcular_total | currency }}</h3>
      </div>
    </router-link>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store/index.js";
export default {
  name: "btn-shop",
  props: {
    color: "",
  },

  data() {
    return {
      direccion: true,
      lastScrollTop: 0,
      nameIdC: "",
      subtotal: 0,
    };
  },
  created() {
    // window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      var st = window.scrollY;
      if (st > this.lastScrollTop) {
        this.direccion = false;
      } else {
        this.direccion = true;
      }
      this.lastScrollTop = st;
    },
  },
  mounted() {
    this.nameIdC = this.$route.params._id;
    this.subtotal = this.calcular_total;
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
  computed: {
    ...mapGetters(["CarShop", "cantOrder", "infoData"]),
    calcular_total() {
      var total = 0;
      this.CarShop.forEach((element) => {
        total = total + element.priceDOM;
      });
      return total;
    },
    cant() {
      // //// console.warn(val);
      var valor = 0;
      var cont = this.CarShop;
      cont.forEach((resp) => {
        valor = valor + resp.orderP.quantity;
      });
      return valor;
    },
    qrId() {
      return store.getters.AddpathQr;
    },
  },
  watch: {
    infoData(value) {
      this.nameIdC = value.qrCode;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
.wrap-brn-shop {
  width: 200px;
  height: 46px;
  background: transparent;
  z-index: 30;
  position: fixed;
  top: 7px;
  right: 50px;

  @include flex-center;
  @include responsive(mobile) {
    width: 100%;
    top: auto;
    bottom: calc(15vh - 46px) !important;
    right: 0px;
  }
  @include responsive(small) {
    width: 100%;
    top: auto;
    bottom: calc(15vh - 46px) !important;
    right: 0px;
  }
}

.btn-car_shopping {
  @include flex-center;
  width: 250px;
  height: 45px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.218);
  transition: 0.2s ease-in-out;

  .text_tal {
    width: 65%;
    transition: 0.3s ease-in-out;
    @include flex-center;
    flex-direction: column;

    color: #fff;
    margin-bottom: -10px;
    h6 {
      line-height: 0px;
    }
    h4 {
      line-height: 0px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
}
._up_ {
  transform: translateX(0px);
}
.downMobile {
  @include responsive(mobile) {
    opacity: 0;
  }
  @include responsive(small) {
    opacity: 0;
  }
  @include responsive(Ipad) {
    opacity: 0;
  }
}
._down_ {
  @include responsive(mobile) {
    transform: translateX(56vw);
  }
  @include responsive(small) {
    transform: translateX(50vw);
  }
  @include responsive(Ipad) {
    transform: translateX(50vw);
  }
}
</style>

