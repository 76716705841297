import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

let env = process.env.NODE_ENV;

const config = {
  apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
  authDomain: "el-ocho.firebaseapp.com",
  databaseURL: "https://el-ocho.firebaseio.com",
  projectId: "el-ocho",
  storageBucket: "el-ocho.appspot.com",
  messagingSenderId: "770366666625",
  appId: "1:770366666625:web:e9a61071d86dd0af9b1a80",
  measurementId: "G-J8JWSS2C1B",
};
const config_dev = {
  apiKey: "AIzaSyBE4DJkIv5Q9GLMr_N_GNTb_CmwHtune1A",
  authDomain: "el-ocho-dev.firebaseapp.com",
  projectId: "el-ocho-dev",
  storageBucket: "el-ocho-dev.appspot.com",
  messagingSenderId: "86761917155",
  appId: "1:86761917155:web:cfe49292873e31d9b9d3d6",
  measurementId: "G-MNELC83VSQ",
};

firebase.initializeApp(env == "production" ? config : config_dev);

export const db = firebase.firestore();
export const fb = firebase;
