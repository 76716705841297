<template>
  <div class="card_template my-6">
    <v-card-title :class="classe"  :id="id">
      <v-chip dark  small :color="colorChip" v-if="chipActive">
        {{ titulo }}
      </v-chip>
      <b v-else>{{ titulo }}</b>
    </v-card-title>

    <div class="chips_label" v-if="required && label">
      <v-chip :color="color" class="text-white" small> {{ label }}</v-chip>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardTemplate",
  props: {
    id:{typeof:String},
    titulo: {
      typeof: String,
      default: "Titulo",
    },
    label: {
      typeof: String,
    },
    color: {
      typeof: "",
      default: "#000",
    },
    required: {
      typeof: Boolean,
      default: false,
    },
    classe: {
      typeof: String,
      default: "titulo",
    },
    chipActive: {
      typeof: Boolean,
      default: false,
    },
    colorChip: {
      typeof: String,
      default: '#fff',
    },
  },
  data: () => ({}),
};
</script>

<style lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";
.card_template {
  width: 100%;
  height: auto;
  border-radius: 1em;
  border: 1px #efefef solid;
  position: relative;
  margin-top: 15px;
  padding: 15px;
  .titulo {
    position: absolute;
    left: 10px;
    top: -16px;
    background-color: #fff;
    padding: 0px 10px 0px 10px;
    font-size: 1.1em;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 60px !important;
    background-color: #ffffff;
    border-radius: 1em;
    height: 40px;
    z-index: 2;
  }
  .chips_label {
    position: absolute;
    right: 15px;
    top: -12px;

    background-color: #fff;
  }
}
.text-white {
  color: #fff !important;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
.chip-active-label {
  font-size: 0.7em;
  color: #fff;
  padding-left: 10px;
  border-radius: 20px;
  padding-right: 10px;
  text-transform: uppercase;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.195);

}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
