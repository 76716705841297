<template>
  <div
    style="transition: 0.3s ease-in-out"
    class="mb-12"
    :class="{ header: flag_cover, 'header-shop': flag_cover == false }"
  >
    <v-img
      v-if="cover && flag_cover == true"
      class="banner"
      :lazy-src="cover.loading"
      :src="cover.original"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row> </template
    ></v-img>

    <v-avatar
      :class="{
        perfil: !isStiky ? true : animation,
        perfil_stiky: !isStiky ? false : !animation,
        perfilee: true,
        'rounded-circle': true,
      }"
      :size="flag_cover ? '90' : '60'"
    >
      <v-img
        v-if="perfil"
        class="rounded-circle"
        :lazy-src="perfil.loading"
        :src="perfil.original"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row> </template
      ></v-img>
    </v-avatar>
    <div class="Name_comer">
      <div class="name">
        <h3 class="font-weight-bold">{{ Name }}</h3>
        <p class="font-weight-light">
          {{ descriptionHeader ? descriptionHeader : infoData.description }}
          <b v-if="closed" class="cerrado">Cerrado</b>
        </p>
      </div>

      <div class="horario" v-if="Schedule">
        <span class="font-weight-bold">Horario </span>
        <p
          class="font-weight-light"
          v-for="(i, index) in Schedule"
          :key="index"
        >
          <span v-if="i != 'cerrado'">
            {{ i }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index.js";
import moment from "moment";
export default {
  name: "Header",
  components: {},
  props: ["isStiky"],
  data() {
    return {
      lastScrollTop: 50,
      flag_cover: false,
      animation: true,
      closed: false,
      perfil: {
        loading: require("../assets/preloader-02.svg"),
        original: require("../assets/preloader-02.svg"),
      },
      cover: {
        loading: require("@/assets/preloader-01.svg"),
        original: require("@/assets/preloader-01.svg"),
      },
      Name: "",
      description: "",
    };
  },
  methods: {
    handleScroll(event) {
      var st = window.scrollY;
      if (st > this.lastScrollTop) {
        this.animation = false;
      } else {
        this.animation = true;
      }
    },
  },
  computed: {
    ...mapGetters(["infoData", "process", "descriptionHeader"]),
    Schedule() {
      let day = moment().format("dddd").toLowerCase();
      let Schedule = this.infoData.qrSchedule;
      let hora = [];
      for (const key in Schedule) {
        if (Object.hasOwnProperty.call(Schedule, key)) {
          const element = Schedule[key];
          if (day == key) {
            if (element.length > 0) {
              var apertura = element[0].opensAt.split(":");
              var cierre = element[0].closesAt.split(":");
              hora.push(
                moment()
                  .hour(apertura[0])
                  .minute(apertura[1])
                  .second(apertura[2])
                  .format("LT") +
                  " - " +
                  moment()
                    .hour(cierre[0])
                    .minute(cierre[1])
                    .second(cierre[2])
                    .format("LT")
              );
              return hora;
            } else {
              hora.push("cerrado");
              return hora;
            }
          }
        }
      }
    },
  },
  watch: {
    $route(to) {
      this.flag_cover = to.name == "Home" ? true : false;
    },
    infoData(v) {
      this.Name = this.infoData.shortName;
      this.description = this.infoData.description;
      this.cover = this.infoData.cover;
      this.perfil = this.infoData.logo;
      var isOpen = this.infoData.qrIsOpen ? true : false;
      this.closed = this.infoData.qrClosed || !isOpen;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted: function () {
    this.flag_cover = this.$route.name == "Home" ? true : false;
    var isOpen = this.infoData.qrIsOpen ? true : false;
    this.closed = this.infoData.qrClosed || !isOpen;
    this.Name = this.infoData.shortName;
    this.description = this.infoData.description;
    this.cover = this.infoData.cover;
    this.perfil = this.infoData.logo;
  },
};
</script>

<style lang="scss">
@import "@/_responsive.scss";
// @import "~vuetify/src/styles/main.sass";
.header-shop {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;

  .Name_comer {
    position: relative;
    width: calc(100% - 80px);
    z-index: 2;
    margin-top: 41px;
    text-align: left !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end !important;
    p {
      font-size: 12px;
    }
    .name {
      width: calc(100% - 110px);
    }
    .horario {
      margin-right: 20px;
      width: 110px;
    }
  }
  .perfilee {
    position: relative;
    border-radius: 1em;
    background-position: center;
    transition: 0.3s ease-in-out;
    background-size: cover;
    margin-left: 10px;
    z-index: 10;
    margin-right: 20px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.049);
  }
}
.header {
  width: 100%;

  height: 200px;
  @include responsive(mobile) {
    height: 230px;
  }
  .banner {
    height: 200px;
    width: 100%;
    background-position: center;
    background-size: contain;
    left: 0px;
    top: 0px;
    border-radius: 1em 1em 0px 0px;
    z-index: 1;
    position: absolute;
  }
  .Name_comer {
    position: absolute;
    width: calc(100% - 120px);
    left: 120px;
    padding-right: 20px;
    top: 200px;
    padding-top: 10px;
    z-index: 2;
    text-align: left !important;

    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 12px;
    }
    @include responsive(mobile) {
      width: calc(100% - 20px);
      left: 20px;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 40px;
    }
    .name {
      width: calc(100% - 110px);
    }
    .horario {
      margin-right: 20px;
      width: 110px;
    }
  }

  .perfil {
    left: 10px;
    position: relative !important;
    top: 150px;
    @include responsive(mobile) {
      left: 10px;
    }
  }
  .perfilee {
    transition: 0.3s ease-in-out;
    border-radius: 1em;
    z-index: 10;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.049);
  }
  .perfil_stiky {
    left: 10px;
    position: relative !important;
    top: 150px;
    @include responsive(mobile) {
      left: 10px;

      z-index: 15 !important;
      left: 0px;
      top: 0px;
      position: fixed !important;
      transform: scale(0.5, 0.5) translateX(-20px) translateY(-30px);

      // height: 50px !important;
      // min-width: 50px !important;
      // width: 40px !important;
    }
  }
}
.cerrado {
  padding: 5px;
  border-radius: 5px;
  margin-left: 0px;
  background-color: red;

  color: #fff;
}
</style>
