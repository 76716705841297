

<template>
  <div class="wrap-avatar-business">
    <v-avatar
      size="50"
      v-for="(item, i) in businessFilter"
      :key="i"
      :class="{ 'avatar-shop': i > 0, 'avatar-item': true }"
    >
      <v-img :src="item.logo.original || imgDefault" :lazy-src="item.logo.original || imgDefault"></v-img>
    </v-avatar>
  </div>
</template>
   <script>
import { mapGetters } from "vuex";
export default {
  name: "header-shop",

  data() {
    return {
      imgDefault: require("@/assets/preloader-02.svg"),
    };
  },
  computed: {
    ...mapGetters(["infoData", "CarShop","allBusiness"]),
     idsCar() {
      let id = [];
      for (let i = 0; i < this.CarShop.length; i++) {
        const element = this.CarShop[i];
        // console.debug(element.business[0]);
         id.includes(element.business[0]) ?  console.debug("no") : id.push(element.business[0]);
      }
      return id;
    },
    businessFilter(){
      return this.allBusiness.filter((item)=> this.idsCar.includes(item['.key']))
    }
  },
  methods: {},
  mounted() {

  
  },
};
</script>
   <style lang="scss" scoped>
.wrap-avatar-business {
  width: 100%;
  height: auto;
  display: flex;
  padding-left: 5px;
  margin-top: 10px;
  .avatar-shop {
    margin-left: -15px;
  }
  .avatar-item {
    filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.106));
  }
}
</style>
   
   