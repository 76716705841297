var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{style:(`border-radius: ${_vm.mobile ? '0px' : '15px'} !important`)},[_c('div',{staticClass:"nav-back-closed"},[_c('v-btn',{staticClass:"btn",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close', 'holasa')}}},[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v("mdi-close")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"dark":"","small":"","color":_vm.color,"absolute":"","right":"","fab":""},on:{"click":function($event){_vm.expandImgDialog = !_vm.expandImgDialog}}},[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v("mdi-image-multiple")])],1)],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"modal-wrap-main"},[_c('v-card',{staticClass:"modal-modiu wrap-trs",attrs:{"elevation":"0"}},[(_vm.dataProduct.images[0])?_c('v-carousel',{staticClass:"rounded-lg",attrs:{"height":"250px","hide-delimiters":"","cycle":"","show-arrows":_vm.dataProduct.images.length > 1 ? true : false,"interval":"4000"}},_vm._l((_vm.dataProduct.images),function(item,i){return _c('v-carousel-item',{key:i,staticClass:"rounded-lg",attrs:{"src":item,"reverse-transition":"slide-x-reverse-transition","transition":"slide-x-transition"}},[_c('div',{staticClass:"banner-modal rounded-lg"},[_c('div',{staticClass:"wrap-text-banner"},[_c('h3',[_vm._v(_vm._s(_vm.dataProduct.name))]),_c('p',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.dataProduct.description)+" ")])])])])}),1):_c('div',{staticClass:"banner-modal-no-image"},[_c('div',{staticClass:"wrap-text-banner"},[_c('h3',[_vm._v(_vm._s(_vm.dataProduct.name))]),_c('p',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.dataProduct.description)+" ")])])]),_vm._l((_vm.addonsShort),function(ad,index){return _c('div',{key:index},[(
              ad.products.length != 0 &&
              ad.deleted == false &&
              ad.active == true
            )?_c('CardTemplate',{staticClass:"mt-8",attrs:{"titulo":ad.name,"required":ad.required,"label":"Obligatorio","color":_vm.color}},[(!_vm.loading)?_c('img',{attrs:{"src":require("@/assets/addons.svg"),"width":"100%","alt":"","srcset":""}}):_vm._e(),(_vm.loading)?_c('Controler',{attrs:{"type":ad.controlType,"Required":ad.required,"color":_vm.color,"addos_id":ad.id,"VControler":_vm.validControler,"datos":ad.products,"max":ad.max,"min":isNaN(ad.min) ? 0 : ad.min},on:{"respalvalue":(r) => {
                  if (r) {
                    ad.valueResp = r;
                  } else {
                    delete ad.valueResp;
                  }
                },"getResul":(pr) => {
                  ad.calValue = pr;
                },"price":_vm.calcular,"reCalcu":_vm.calcular}}):_vm._e()],1):_vm._e()],1)}),_c('v-row',{staticClass:"px-2 mt-4 mb-12"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',{staticClass:"text-left",attrs:{"for":""}},[_c('h4',[_vm._v("Agregar comentario")])]),_c('v-textarea',{staticClass:"rounded-lg",attrs:{"filled":"","auto-grow":"","color":_vm.color,"rounded":"","required":false,"dense":"","placeholder":"Ingresa tu comentario aquí...","rows":"1","row-height":"100"},model:{value:(_vm.order.comments),callback:function ($$v) {_vm.$set(_vm.order, "comments", $$v)},expression:"order.comments"}})],1)],1)],2),_c('div',{staticClass:"sticky-bottom px-3 py-3"},[_c('v-card-actions',{staticClass:"control-btn"},[_c('div',{staticClass:"contol"},[_c('v-btn',{attrs:{"icon":"","small":"","outlined":""},on:{"click":function($event){_vm.order.quantity <= 1 ? _vm.order.quantity : _vm.order.quantity--,
                  _vm.calcular()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus ")])],1),_c('div',{staticClass:"resultado"},[_c('h3',[_vm._v(_vm._s(_vm.order.quantity))])]),_c('v-btn',{attrs:{"color":_vm.color,"small":"","outlined":"","icon":""},on:{"click":_vm.sumarQuality}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-btn',{staticClass:"rounded-lg btn-agregar",attrs:{"color":_vm.color,"width":"60%","height":"40px","elevation":"0","loading":_vm.btnLoading},on:{"click":_vm.validate}},[_vm._v(" Agregar "),_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm._f("currency")(_vm.priceDOM)))])])],1)],1)],1)]),(_vm.expandImgDialog)?_c('v-dialog',{staticStyle:{"border-radius":"10px"},attrs:{"max-width":"550"},model:{value:(_vm.expandImgDialog),callback:function ($$v) {_vm.expandImgDialog=$$v},expression:"expandImgDialog"}},[_c('expandimage',{attrs:{"data":_vm.dataProduct.images}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }