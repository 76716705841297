import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './main.scss'
import './firebase';
import firebase from 'firebase/compat/app';
import VueFirestore from 'vue-firestore';
import VueTelInputVuetify from "vue-tel-input-vuetify";

Vue.use(VueFirestore)
Vue.use(VueTelInputVuetify);
Vue.use(firebase)

Object.defineProperty(Vue.prototype, "firebase", {
    get() {
        return this.$root.firebase;
    }
});
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
