<template>
  <v-card :style="`border-radius: ${mobile ? '0px' : '15px'} !important`">
    <div class="nav-back-closed">
      <v-btn class="btn" @click="$emit('close', 'holasa')" icon>
        <v-icon color="#fff">mdi-close</v-icon>
      </v-btn>
      <v-btn
        dark
        @click="expandImgDialog = !expandImgDialog"
        small
        :color="color"
        absolute
        right
        class="mr-1"
        fab
      >
        <v-icon color="#fff">mdi-image-multiple</v-icon>
      </v-btn>
    </div>

    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="modal-wrap-main">
        <v-card class="modal-modiu wrap-trs" elevation="0">
          <v-carousel
            v-if="dataProduct.images[0]"
            height="250px"
            hide-delimiters
            cycle
            :show-arrows="dataProduct.images.length > 1 ? true : false"
            class="rounded-lg"
            interval="4000"
          >
            <v-carousel-item
              v-for="(item, i) in dataProduct.images"
              :key="i"
              :src="item"
              class="rounded-lg"
              reverse-transition="slide-x-reverse-transition"
              transition="slide-x-transition"
            >
              <div class="banner-modal rounded-lg">
                <div class="wrap-text-banner">
                  <h3>{{ dataProduct.name }}</h3>
                  <p class="caption">
                    {{ dataProduct.description }}
                  </p>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
          <div v-else class="banner-modal-no-image">
            <div class="wrap-text-banner">
              <h3>{{ dataProduct.name }}</h3>
              <p class="caption">
                {{ dataProduct.description }}
              </p>
            </div>
          </div>

          <div v-for="(ad, index) in addonsShort" :key="index">
            <CardTemplate
              v-if="
                ad.products.length != 0 &&
                ad.deleted == false &&
                ad.active == true
              "
              class="mt-8"
              :titulo="ad.name"
              :required="ad.required"
              label="Obligatorio"
              :color="color"
            >
              <img
                v-if="!loading"
                src="@/assets/addons.svg"
                width="100%"
                alt=""
                srcset=""
              />
              <Controler
                v-if="loading"
                :type="ad.controlType"
                :Required="ad.required"
                :color="color"
                :addos_id="ad.id"
                :VControler="validControler"
                :datos="ad.products"
                :max="ad.max"
                @respalvalue="
                  (r) => {
                    if (r) {
                      ad.valueResp = r;
                    } else {
                      delete ad.valueResp;
                    }
                  }
                "
                :min="isNaN(ad.min) ? 0 : ad.min"
                @getResul="
                  (pr) => {
                    ad.calValue = pr;
                  }
                "
                @price="calcular"
                @reCalcu="calcular"
              />
            </CardTemplate>
          </div>
          <v-row class="px-2 mt-4 mb-12">
            <v-col cols="12" sm="12" md="12">
              <label class="text-left" for="">
                <h4>Agregar comentario</h4>
              </label>
              <v-textarea
                filled
                auto-grow
                :color="color"
                class="rounded-lg"
                rounded
                :required="false"
                dense
                placeholder="Ingresa tu comentario aquí..."
                v-model="order.comments"
                rows="1"
                row-height="100"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>

        <div class="sticky-bottom px-3 py-3">
          <v-card-actions class="control-btn">
            <div class="contol">
              <v-btn
                icon
                small
                outlined
                @click="
                  order.quantity <= 1 ? order.quantity : order.quantity--,
                    calcular()
                "
              >
                <v-icon small> mdi-minus </v-icon>
              </v-btn>
              <div class="resultado">
                <h3>{{ order.quantity }}</h3>
              </div>
              <v-btn :color="color" small outlined icon @click="sumarQuality">
                <v-icon small> mdi-plus </v-icon>
              </v-btn>
            </div>

            <v-btn
              :color="color"
              width="60%"
              class="rounded-lg btn-agregar"
              height="40px"
              @click="validate"
              elevation="0"
              :loading="btnLoading"
            >
              Agregar <b class="ml-3">{{ priceDOM | currency }}</b>
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-form>
    <v-dialog
      v-model="expandImgDialog"
      max-width="550"
      style="border-radius: 10px"
      v-if="expandImgDialog"
    >
      <expandimage :data="dataProduct.images" />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { db, fb } from "@/firebase";
import CardTemplate from "@/components/cardTemplate";
import Controler from "@/components/controler";
import expandimage from "@/components/expand_image.vue";
import store from "@/store/index.js";

export default {
  name: "DialogMenuOrder",
  components: {
    CardTemplate,
    Controler,
    expandimage,
  },

  props: ["id_product", "dataProduct", "addonsProps", "dialog", "color"],

  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
  data() {
    return {
      valid: true,
      validControler: true,
      price: 0,
      expandImgDialog: false,
      btnLoading: false,
      priceDOM: 0,
      addons: [],
      alert: false,
      mobile: screen.width <= 550 ? true : false,
      productosArray: [],
      loading: false,
      order: {
        addons: [],
        comments: "",
        id: "",
        quantity: 1,
      },
    };
  },

  async mounted() {
    this.addons = JSON.parse(JSON.stringify(this.addonsProps));
    // console.debug(this.dataProduct);
    await this.getProductAddons();
    if (this.dataProduct.isDiscount == true) {
      this.priceDOM = this.dataProduct.discountPrice;
      this.price = this.dataProduct.discountPrice;
    } else {
      this.priceDOM = this.dataProduct.price;
      this.price = this.dataProduct.price;
    }
  },
  computed: {
    addonsShort() {
      function compare(a, b) {
        // if (a.required) return -1;
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
       
        return 0;
      }
      return this.addons.sort(compare);
    },
  },
  methods: {
    ...mapActions(["addRespalOrders", "Alert_", "agregarOrder", "orderCanti"]),
    sumarQuality() {
      if (this.dataProduct.isStockRequiered) {
        // // console.log("no");
        if (this.order.quantity >= Number(this.dataProduct.stockAmount)) {
          this.Alert_({
            text: "Has alcanzado el máximo de inventario de este producto. 😬",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "fa-solid fa-check",
            type: "error",
          });
          return;
        }
      }

      if (this.dataProduct.userMax > 0) {
        if (this.order.quantity < this.dataProduct.userMax) {
          this.order.quantity++;
        } else {
          this.Alert_({
            text: "Has alcanzado el limite permitido para este producto. 😬",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "fa-solid fa-check",
            type: "error",
          });
        }
      } else {
        this.order.quantity++;
      }

      this.calcular();
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.validControler = true;
        this.AddOrder();
      } else {
        this.validControler = false;
        this.Alert_({
          text: "Favor selecciona las opciones requeridas 🙂",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "fa-solid fa-check",
          type: "error",
        });
      }
    },
    AddOrder() {
      var datos = Object.assign({}, this.dataProduct);
      this.order.id = this.id_product;
      datos.addonsArray = Object.assign([], this.addonsShort);
      datos.orderP = this.order;
      datos.priceDOM = this.priceDOM;
      this.addRespalOrders(datos);
      this.Alert_({
        text: "Producto agregado",
        timeout: 2000,
        btn_closed: true,
        icon: true,
        iconType: "fa-solid fa-check",
        type: "success",
      });
      this.btnLoading = true;
      setTimeout(() => {
        this.$emit("close", "holasa");
      }, 1000);
      // });
    },
    calcular() {
      var calculo = 0;
      this.addons.forEach((element) => {
        calculo = calculo + element.calValue || 0;
      });

      this.priceDOM = calculo + this.price;

      this.priceDOM = this.priceDOM * this.order.quantity;
    },
    getProductAddons() {
      const recorrer = new Promise((resolve, reject) => {
        const ProductIdArray = [];

        for (let i = 0; i < this.addonsShort.length; i++) {
          const product = this.addonsShort[i].products;

          for (let p = 0; p < product.length; p++) {
            const ProductId = product[p].productId;
            ProductIdArray.push(ProductId);
          }
        }

        resolve(ProductIdArray);
      });

      recorrer.then(async (d) => {
        for (let i = 0; i < d.length; i = i + 10) {
          var id_separados = d.slice(i, i + 10);

          await db
            .collection(`qrProducts`)
            .where(fb.firestore.FieldPath.documentId(), "in", id_separados)
            .get()
            .then((data) => {
              for (let r = 0; r < data.docs.length; r++) {
                const element = data.docs[r].data();
                element.id = data.docs[r].id;
                this.productosArray.push(element);
              }
            });
        }
        this.addonsShort.forEach((addon) => {
          addon.calValue = 0;
          addon.products.forEach((producto) => {
            let search = this.productosArray.find(
              (e) => e.id == producto.productId
            );

            producto.resul = 0;
            producto.originalProduct = search ? search : "";
          });
        });

        this.loading = true;
      });
    },
    addInfoProduc() {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
</style>
