<template>
  <v-card-text class="wrap-sucess">
    <div class="lottie-container">
      <lottie :options="defaultOptions" />
    </div>
    <h2 class="exito_ text-center">Éxito, tu orden ha sido colocada</h2>
    <p class="text-center">Revisa el estado de tu orden</p>
    <v-btn
      v-for="(item, i) in orderResponse"
      :key="i"
      height="55"
      :color="color"
      dark
      link
      target="_blank"
      block
      class="rounded-lg mt-2"
      elevation="0"
      :href="`${urlRedirect}/tracking/${item.orderId}`"
    >
      <v-avatar size="40" class="mr-4">
        <v-img :src="item.logo.original" :lazy-src="item.logo.loading"> </v-img>
      </v-avatar>
      {{ item.businessName }}
    </v-btn>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Lottie from "@/components/Lottie";
import * as susscess from "@/assets/Lottie/susscess.json";
import productList from "@/components/productList.vue";
export default {
  name: "DialogSuccess",
  components: {
    productList,
    Lottie,
  },
  props: ["dialog", "data", "color", "orderResponse"],

  data() {
    return {
      urlRedirect:
        process.env.NODE_ENV == "production"
          ? "https://menu.hn"
          : "https://menu-hn-dev.web.app",
      animationSpeed: 1,
      defaultOptions: {
        animationData: susscess.default,
        loop: true,
        autoplay: true,
        lenguaje: "",
      },
    };
  },
  methods: {
    ...mapActions(["CleanData"]),
  },
  mounted() {
    this.CleanData();
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";
.Modal_susecss {
  border-radius: 1em !important;
  padding: 10px;
  text-justify: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-modal {
  height: 250px;
  width: 100%;
  display: flex;
  color: rgb(177, 177, 177);
  justify-content: center;
  align-items: flex-end;
  padding: 10px;

  background-size: cover;
  background-position: center;
  border-radius: 1em;
}
.v-sheet.modal-modiu {
  border-radius: 1em !important;
  // padding: 10px;
  text-align: center;
}
.wrap-text-banner {
  background-color: rgba(32, 32, 32, 0.418);
  backdrop-filter: saturate(180%) blur(10px);
  border-radius: 1em;
  padding: 10px;
}
.exito_ {
  transform: translateY(-20px);
}
.lottie-container{
  width: 70%;
  
}
.wrap-sucess{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
