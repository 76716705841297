import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    props:false,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/landing.vue')
  },
  {
    path: '/planes',
    name: 'planes',
    props:false,
    component: () => import('../views/planes.vue')
  },
  {
    path: '/:_id',
    name: 'Home',
    component: Home,
    props:true,

  },
  {
    path: '/:_id/shoppingCart',
    name: 'ShoppingCart',
    props:true,
    component: () => import('../views/shoppingCart.vue')
  },
  {
    path: '/tracking/:_id',
    name: 'tracking',
    props:true,
    component: () => import('../views/tracking.vue')
  },
  {
    path: '/successOrder/:_id',
    name: 'successOrder',
    props:true,
    component: () => import('../views/OrderSuccess.vue')
  },
  {
    path: '/:_id/number',
    name: 'pageOtp',
    props:true,
    component: () => import('../views/pageOtp.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
