<template>
  <v-container
    class="bussines-landing"
    v-if="isFather && typeLayaut == 'directorybyCity'"
  >
    <v-stepper elevation="0" :color="color" v-model="ee1" class="wrap-fluid">
      <v-stepper-header elevation="0">
        <v-stepper-step :color="color" :complete="ee1 > 1" step="1">
          Selecciona la Cuidad
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :color="color" :complete="ee1 > 2" step="2">
          Comercio
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12" elevation="0">
            <label>Selecciona tu ciudad</label>
            <v-select
              :color="color"
              full-width
              height="28px"
              :items="cities"
              item-value="id"
              class="rounded-lg mb-6"
              filled
              :loading="loading"
              item-text="name"
              :rules="RequiredRule"
              placeholder="Seleccia una ciudad"
              @change="setCityState"
              required
              :item-color="color"
              v-model="valueCity"
              rounded
              hide-details
            ></v-select>
          </v-card>
          <v-btn
            :color="color"
            width="100%"
            v-if="valueCity && bussinesFilter[0]"
            elevation="0"
            height="50px"
            dark
            @click="continu"
            class="rounded-lg"
            >continuar
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-12" elevation="0">
            <v-row>
              <v-col
                md="6"
                sm="6"
                cols="12"
                v-for="(i, index) in bussinesFilter"
                :key="index"
              >
                <div class="wrap-bussines" @click="sendlink(i.qrCode)">
                  <v-img
                    max-height="50"
                    max-width="50"
                    style="border-radius: 50%"
                    :lazy-src="i.logo.loading"
                    :src="i.logo.original"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <div class="text-name">
                    <div>
                      <h4>{{ i.shortName }}</h4>
                      <p class="font-weight-light text-caption">
                        {{ i.description }}
                      </p>
                    </div>
                    <v-btn icon elevation="0" :color="color">
                      <v-icon> mdi-link-variant </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <!-- <v-btn
            :color="color"
            width="100%"
            elevation="0"
            height="50px"
            dark
            @click="e1 = 1"
            class="rounded-lg"
            >regresar
          </v-btn> -->
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
  <div
    class="wrap-menu-layaut-full"
    v-else-if="isFather && typeLayaut == 'directoryForOrder'"
  >
    <div class="cover-img">
      <v-img
        width="100%"
        height="200"
        :src="
          getSelectBusiness
            ? getSelectBusiness.cover.original
            : require('@/assets/preloader-02.svg')
        "
        style="border-radius: 10px"
        :lazy-src="
          getSelectBusiness
            ? getSelectBusiness.cover.loading
            : require('@/assets/preloader-02.svg')
        "
      >
      </v-img>
      <transition name="bounce">
        <v-img
          v-if="show_info"
          class="logo-center"
          style="border-radius: 50%"
          width="80"
          height="80"
          alt="Avatar"
          :src="
            getSelectBusiness
              ? getSelectBusiness.logo.original
              : require('@/assets/preloader-02.svg')
          "
          :lazy-src="
            getSelectBusiness
              ? getSelectBusiness.logo.loading
              : require('@/assets/preloader-02.svg')
          "
        />
      </transition>
    </div>

    <CardTemplate titulo="Selecciona un comercio" :color="color">
      <div :class="{ 'cta-touch': true, 'animation-cta': !selBusiness }">
        <v-chip-group
          active-class="primary--text"
          :color="color"
          v-model="selBusiness"
          column
        >
          <v-chip
            large
            outlined
            :color="color"
            v-for="item in sortedArray"
            :key="item[`.key`]"
            @click="setBusinnes(item)"
            :value="item"
            small
            class="pl-2"
          >
            <!-- <div class="item-business"> -->
            <v-img
              style="border-radius: 50%"
              width="40"
              class="mr-2"
              v-if="item.logo"
              alt="Avatar"
              :src="item.logo.original"
              :lazy-src="item.logo.loading"
            />
            <b>{{ item.shortName }}</b>
            <!-- </div> -->
          </v-chip>
        </v-chip-group>
      </div>
    </CardTemplate>
    <!-- <transition name="fade"> -->
    <Menu :showInfo="show_info" />
    <!-- </transition> -->
  </div>
  <v-container v-else-if="isFather && typeLayaut == 'businessDirectory'">
    <v-card class="mt-12" elevation="0">
      <v-row>
        <v-col
          md="6"
          sm="6"
          cols="12"
          v-for="(i, index) in sortedArray"
          :key="index"
        >
          <div class="wrap-bussines" @click="sendlink(i.qrCode)">
            <v-img
              max-height="50"
              max-width="50"
              style="border-radius: 50%"
              :lazy-src="i.logo.loading"
              :src="i.logo.original"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="text-name">
              <div>
                <h4>{{ i.shortName }}</h4>
                <p class="font-weight-light text-caption">
                  {{ i.description }}
                </p>
              </div>
              <v-btn icon elevation="0" :color="color">
                <v-icon> mdi-link-variant </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import CardTemplate from "@/components/cardTemplate";
import headerShop from "@/components/headerShopBusiness";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import Menu from "../views/menu.vue";
import { db, fb } from "@/firebase";
import store from "@/store/index.js";
export default {
  name: "businessLanding",
  components: {
    CardTemplate,
    headerShop,
    Menu,
  },
  props: ["typeLayaut"],
  data: () => ({
    ee1: 1,
    isCompras: false,
    selBusiness: null,
    show_info: false,
    loading: true,
    loadingSkeleton: false,
    idBusiness: null,
    valueCity: null,
    color: "#00cdbc",
    bgColor: "",
    imgBg: "",
    allCities: [],
    qrBackgroundType: true,
    loader: true,
    nameComeers: null,
    RequiredRule: [(v) => !!v || "Requerido"],
    description: "",
    business: [],
    addonsData: [],
    ruta: "",
    flag_ruta: false,

    flag_landing: false,
    Loding: true,
    chipsGroup: [],
  }),

  computed: {
    ...mapGetters(["infoData", "valueE1", "getSelectBusiness"]),
    sortedArray: function () {
      function compare(a, b) {
        if (a.shortName < b.shortName) return -1;
        if (a.shortName > b.shortName) return 1;
        return 0;
      }

      return this.business.sort(compare);
    },
    sortedChips: function () {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return Object.assign([], this.chipsGroup.sort(compare));
    },
    bussinesFilter() {
      return this.business.filter((i) =>
        i.qrCity ? i.qrCity == this.valueCity : false
      );
    },

    cities() {
      var citiesByBusiness = [];
      this.business.forEach(async (item) => {
        if (item.qrCity) {
          // for (let i = 0; i < item.qrCity.length; i++) {
          //   const element = item.qrCity[i];
          citiesByBusiness.push(item.qrCity);
        }
        // console.log("citiesByBusiness", citiesByBusiness);
        // }
      });

      var filter = this.allCities.filter((e) =>
        citiesByBusiness.includes(e.id)
      );

      return filter;
    },

    isFather() {
      if (this.infoData.qrIsFather) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions([
      "Alert_",
      "addData_info",
      "Add_Menu",
      "Add_ComersId",
      "Add_chipsMenu",
      "Add_AddonsData",
      "_setAllBusiness",
      "CleanData",
      "_setE1",
      "_descripHeader",
      "Add_selectBusiness",
    ]),
    continu() {
      this.ee1 = 2;
      this._setE1(2);
    },
    sendlink(qrcode) {
      if (qrcode) {
        window.open(
          `http://${this.isCompras ? "compras" : "menu"}.hn/${qrcode}`,
          "_blank"
        );
      }
    },
    setCityState(value) {
      let city = this.allCities.find((item) => item.id == value);
      // // console.debug(city);
      this._descripHeader(city.name);

      //  const city = this.allCities
    },
    getAllBusineessByFather() {
      this.$binding(
        "business",
        db
          .collection("businesses")
          .where("qrIsChildOf", "==", this.idBusiness)
          .where("deleted", "==", false)
          .where("qrActive", "==", true)
      ).then((resp) => {
        this._setAllBusiness(resp);
        this.setBusinnes(resp[0]);
        setTimeout(() => {
          this.selBusiness = resp[0];
        }, 600);
      });
    },
    get_bussines(bussines) {
      return new Promise((resolve, rejct) => {
        db.collection("businesses")
          .where("qrCode", "==", bussines)
          .where("qrActive", "==", true)
          .where("deleted", "==", false)
          .onSnapshot((a) => {
            a.docs.forEach((element) => {
              var datos = element.data();
              datos.id = element.id;
              this.idBusiness = datos.id;
              this.addData_info(datos);
              this.qrBackgroundType = datos.qrBackgroundType;
              this.nameComeers = datos.shortName;
              this.bgColor = datos.qrBackgroundColor;
              this.imgBg = datos.qrBackgroundImage;
              this.color = datos.qrThemeColor || "#ff5900";
            });
            this.loading = false;
            resolve(a.docs);
          });
      });
    },
    async setBusinnes(item) {
      console.log("setBusinnes", item);

      this.show_info = false;
      this.Add_selectBusiness(item);
      this.Add_chipsMenu([]);
      this.Add_Menu([]);
      this.Add_AddonsData([]);
      await this.get_menu(item);
      this.show_info = true;
    },
    get_menu(item) {
      return new Promise(async (resolve, reject) => {
        console.debug("key", item[`.key`]);
        await db
          .collection("businesses")
          .doc(item[`.key`])
          .collection("qrMenu")
          .where("active", "==", true)
          .where("deleted", "==", false)
          .onSnapshot(async (e) => {
            // this.menu = e.docs;
            // console.debug(e);
            var menu = [];
            this.chipsGroup = [];
            for (let index = 0; index < e.docs.length; index++) {
              const response = e.docs[index];

              var groupProduct = response.data();
              // console.debug(groupProduct);
              groupProduct.id = response.id;
              var res = await this.get_product(groupProduct.id);

              var productos = [];
              for (let p = 0; p < res.length; p++) {
                const produc = res[p];
                var product_menu = produc.data();
                product_menu.id = produc.id;
                productos.push(product_menu);
              }
              groupProduct.products = productos;

              if (
                groupProduct.active == true &&
                groupProduct.isAvailable == true &&
                groupProduct.deleted == false &&
                groupProduct.products.length > 0
              ) {
                var obj = {
                  id: groupProduct.id,
                  name: groupProduct.name,
                  position: groupProduct.position,
                };
                this.chipsGroup.push(obj);
                menu.push(groupProduct);
              }
            }

            this.short_Menu_Product(menu);
            await this.Add_chipsMenu(this.sortedChips);
            // //// console.debug("cambiooo");
          });

        return resolve(true);
      });
    },
    get_product(menu_id) {
      return new Promise((resolve, rejct) => {
        db.collection("qrProducts")
          .where("menu", "array-contains", menu_id)
          .where("active", "==", true)
          .where("deleted", "==", false)
          .onSnapshot((e) => {
            resolve(e.docs);
          });
      });
    },
    short_Menu_Product(menu) {
      // console.debug(menu);
      var Array_Pro = menu;
      for (let index = 0; index < Array_Pro.length; index++) {
        const element = Array_Pro[index].products;
        function compare(a, b) {
          if (a.isAvailable) {
          } else {
            a.position = element.length;
          }
          if (a.position < b.position) return -1;
          if (a.position > b.position) return 1;
          if (a.position == "") return 1;
          if (!a.position) return 1;
        }
        Array_Pro[index].products = element.sort(compare);
      }

      this.Add_Menu(Array_Pro);
      // console.debug(Array_Pro);
    },
    // llenado addonsData
    getAddons(id_bussines) {
      return new Promise((resolve, rejct) => {
        db.collection("qrAddons")
          .where("businessId", "==", id_bussines)
          .where("active", "==", true)
          .where("deleted", "==", false)
          // .get()
          .onSnapshot((e) => {
            this.addonsData = [];
            for (let i = 0; i < e.docs.length; i++) {
              const addons = e.docs[i].data();
              addons.id = e.docs[i].id;

              if (!addons.position) {
                addons.position = 0;
              }
              // if (addons.required) {
              //   addons.position = -1;
              // }
              this.addonsData.push(addons);
            }
            this.Add_AddonsData(this.addonsData);
            resolve(e.docs);
          });
      });
    },
  },
  watch: {
    valueE1(e) {
      // // console.debug(e);
      this.ee1 = e;
    },
    async selBusiness(v) {
      await this.getAddons(v[`.key`]);
    },
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("es-HN", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },

  mounted() {
    this.$nextTick(async () => {
      var getDomain = document.domain;
      var search = getDomain.search("compra");
      if (search != -1) {
        this.isCompras = true;
      }

      let _id = this.$route.params._id;
      this.get_bussines(_id).then((Response) => {
        console.debug(Response);
        this.getAllBusineessByFather();
      });

      await axios
        .get(`${process.env.VUE_APP_EL_OCHO}/httpCityNames`)
        .then((response) => {
          this.allCities = response.data;
        });
    });
  },
};
</script>
<style lang="scss">
.bussines-landing {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  padding-top: 80px;

  .wrap-form-bussines {
    max-width: 400px;
  }
}

.wrap-fluid {
  width: 100%;
}

.wrap-bussines {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 10px;
  margin: 5px;
  background-color: #fff;
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px #8c8c8c2d;

  &:hover {
    background-color: #f2f2f2;
  }

  .text-name {
    margin-left: 10px;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 50px);
  }
}

.btn-card-return {
  z-index: 100;
  position: absolute;
  top: -0px;
  left: -10px;
}

.wrap-menu-layaut-full {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // transition: 3s ease-in-out;
}

.item-business {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cover-img {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 50px;

  .logo-center {
    position: absolute;
    bottom: -40px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.animation-cta {
  border-radius: 15px;
  // border: solid 1px #ff5900;
}
</style>
